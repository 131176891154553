<script setup>
import { onMounted } from 'vue';
import geoData from './data.js'

const emits = defineEmits(['click']);

const firstLevel = geoData.filter(item => item.level === 3);
getArr(firstLevel)
function getArr(data) {
  for (let i = 0; i < data.length; i++) {
    const el = data[i];
    const subArr = geoData.filter(a => a.parentId === el.id)
    el.children = subArr;
    getArr(el.children);
  }
}

function getInfo(item) {
  emits('click', item)
}

onMounted(() => {
  const firstDomList = document.querySelectorAll('.first');
  firstDomList.forEach(div => {
    div.addEventListener('mouseenter', e => {
      firstDomList.forEach(dom => {
        dom.classList.remove('active');
      })
      e.target.classList.add('active');
    })
  });
})

</script>

<template>
   <div class="geoContainer">
    <template
      v-for="(item,index) in firstLevel.reverse()"
      >
    <div
      class="first"
      :style="{'backgroundColor':item.color}"
      v-if="item.children.length>0"
    >
      <div
        class="title"
        :title="`${item.ename} ${item.start} - ${item.end}`"
        >
        <span style="transform: rotate(90deg);
          white-space: nowrap">
        {{ item.ename }}
        </span>

      </div>
      <div class="secondContainer">
        <div class="firstLabel">{{ item.ename }}</div>
        <div class="second" v-for=" (subItem,index) in item.children.reverse()" :key="item.id" >
            <div
              class="title"
              :title="`${subItem.ename} ${subItem.start} - ${subItem.end}`"
              @click="getInfo(subItem)"
              :style="{'backgroundColor':subItem.color}"
              >
              {{ subItem.ename }}
            </div>
            <div class="thirdContainer">
                <div class="third" v-for=" (miniItem,index) in subItem.children.reverse()" :key="miniItem.id" :style="{'backgroundColor':miniItem.color}" >
                  <div
                    class="title"
                    :title="`${miniItem.ename} ${miniItem.start} - ${miniItem.end}`"
                    @click="getInfo(miniItem)"
                    >
                    {{ miniItem.ename }}
                  </div>
            </div>
            </div>

        </div>
      </div>
  </div>

  </template>
  </div>

</template>

<style lang="less" scoped>

.geoContainer{
  width:300px;
  height:100%;
  overflow:auto;
  padding: 2px;
  box-sizing: border-box;
  background-color: #3f4043;
  user-select: none;
}
::-webkit-scrollbar{
  width: 5px;
  height: 5px;
  background-color: #FFF;
}
::-webkit-scrollbar-thumb{
  background-color: #9d9d9d;
  border-radius:20px;
}
.first{
  display:flex;
  height:40px;
  cursor: pointer;

  &>.title{
    opacity: 0;
    width:25px;
    /* 字体垂直居中 */
    display:flex;
    justify-content:center;
    align-items: center;
    border: #000 1px solid;
    span{
      color:#FFF;
      font-size: 14px;
    }
  }

  .secondContainer{
    flex:1;
    background-color: #3f4043;
    border: 1px solid #000;
    .firstLabel{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFF;
    }

    .second{
      display:none;
    }
  }
}
.first.active{
  height:max-content;
  font-size:14px;
  &>.title{
    opacity: 1;
    width:25px;

    /* 字体垂直居中 */
    display:flex;
    justify-content:center;
    align-items: center;
    border: #000 1px solid;
    span{
      color:#FFF;
    }
  }

  .secondContainer{
    flex:1;
    background-color: transparent;
    border: 1px solid #000;
    .firstLabel{
      display: none;
    }

    .second{
      display:flex;
      .title{
        width: 120px;
        display:flex;
        justify-content:center;
        align-items:center;
        border: #000 0.5px solid;
      }

      .thirdContainer{
        flex:1;
        .third .title{
          width: 100%;
          height: 30px;
          display:flex;
          justify-content:center;
          align-items:center;
          border: #000 0.5px solid;
        }
      }
    }
  }
}

</style>
