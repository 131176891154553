/*
 * @Author: lg9527 13691080728@126.com
 * @Date: 2023-09-25 17:42:34
 * @LastEditors: lg9527 13691080728@126.com
 * @LastEditTime: 2024-03-15 11:33:33
 * @FilePath: \zky_webVue\src\views\visualized\DrawPolygonTool.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import L from 'leaflet';

function DrawPolygonTool(map) {
  this.map = map;
  this.polygonLayer = null;
  this.points = []; // 用于存储多边形的顶点
  this.drawing = false; // 用于跟踪是否正在绘制
  this.startDrawingPolygonHandler = this.startDrawingPolygon.bind(this);
  this.continueDrawingPolygonHandler = this.continueDrawingPolygon.bind(this);
}

DrawPolygonTool.prototype.startDrawing = function() {
  if (this.drawing) {
    return; // 如果已经在绘制中，不要重复启动
  }

  this.drawing = true;

  this.map.on('click', this.startDrawingPolygonHandler);
};

DrawPolygonTool.prototype.startDrawingPolygon = function(e) {
  this.points.push(e.latlng);
  this.drawPolygon();

  this.map.off('click', this.startDrawingPolygonHandler);
  this.map.on('click', this.continueDrawingPolygonHandler);
};

DrawPolygonTool.prototype.continueDrawingPolygon = function(e) {
  this.points.push(e.latlng);
  this.drawPolygon();
};

DrawPolygonTool.prototype.drawPolygon = function() {
  if (this.polygonLayer) {
    this.map.removeLayer(this.polygonLayer);
  }

  if (this.points.length < 1) {
    // 至少需要3个点来构成一个多边形
    return;
  }

  this.polygonLayer = L.polygon(this.points, { color: 'rgba(33,237,210,0.65)', weight: 2 }).addTo(this.map);
};

DrawPolygonTool.prototype.clear = function() {
  if (this.drawing) {
    this.map.off('click', this.startDrawingPolygonHandler);
    this.map.off('click', this.continueDrawingPolygonHandler);
    this.drawing = false;
  }

  if (this.polygonLayer) {
    this.map.removeLayer(this.polygonLayer);
    this.polygonLayer = null;
    this.points = [];
  }
};

DrawPolygonTool.prototype.getResultAsGeoJSON = function() {
  if (this.points.length < 3) {
    return null; // 无法构成一个有效的多边形
  }

  var coordinates = this.points.map(function(point) {
    return [point.lng, point.lat];
  });

  // 确保多边形的第一个点和最后一个点相同，构成封闭多边形
  coordinates.push(coordinates[0]);

  var polygon = {
    type: "Feature",
    properties: {},
    geometry: {
      type: "Polygon",
      coordinates: [coordinates]
    }
  };

  return polygon;
};

export default DrawPolygonTool;
