<template>
  <!-- 主容器 -->
  <div>
    <!-- 地图容器 -->
    <div class="m-pg-sub" id="map"></div>
   <!-- 下载按钮 -->
   <el-button class="download-btn" type="primary" icon="el-icon-download" @click="downloadMap">下载地图</el-button>

    <div class="leaflet-draw-toolbar" id="draw-toolbar"></div>


    <!-- 搜索按钮 -->
    <el-button class="search-btn" type="primary" icon="el-icon-search" circle @click="isShowSearch=true"></el-button>
    <!-- 基础地图切换组件 -->
    <BaseMapSwitch class="base-map-container" ref="mapswitchRef"></BaseMapSwitch>
    <!-- 左侧功能面板 -->
    <div class="box-card">
      <!-- 功能菜单 -->
      <ul class="cos-menu">
        <li @click="layerClick">地质图层</li>
        <li @click="timelineClick">时间轴</li>
        <li @click="downloadMap">下载地图</li>
      </ul>
      <!-- 地质图层菜单 -->
      <el-card class="el-card--dztc" shadow="always" v-show="mapLayerMenu" style="overflow-y: auto;">
        <div slot="header" class="clearfix">
          <span style="font-weight: bold;">地质图层</span>
        </div>
        <div style="overflow-y: auto;">
          <WmtsCatalogTree ref="catalogTreeRef"></WmtsCatalogTree>
        </div>
      </el-card>
      <!-- 时间轴菜单 -->
      <el-card class="time-line-card" shadow="always" v-if="timeLineMenu" style="display: flex;flex-direction: column;">
        <div slot="header" class="clearfix">
          <span style="font-weight: bold;">时间轴</span>
        </div>
        <TimeLine @click="handleTimeLineClick"></TimeLine>
      </el-card>
    </div>
    <!-- 搜索面板 -->
    <div class="search-warpper">
      <SearchPanel class="search-card" v-show="isShowSearch" @close="isShowSearch=false" @success="onSearchSuccess"
                   @DrawRect="doDrawRect" @DrawPolygon="doDrawPolygon" @clear="doClear"></SearchPanel>
    </div>
    <!-- 搜索结果面板 -->
    <div class="search-result-panel" v-show="isShowSearchResult" v-loading="loading"
         element-loading-text="正在拼命检索...">
      <div class="header">
        <span style="font-weight: bold;">找到{{ totalRows }}个结果</span>
        <div class="header-r">
          <el-button icon="el-icon-data-line" size="mini" round @click="onDataAnalysis">数据分析</el-button>
          <el-button icon="el-icon-map-location" size="mini" round @click="gotoAncientMap">古地图</el-button>
          <i @click="isShowSearchResult=false" class="close-btn el-icon-close"></i>
        </div>
      </div>
      <div class="list-warapper">
        <!-- 数据列表组件 -->
        <sample-list :result-data="resultData" @itemClick="onItemClick"></sample-list>
      </div>
      <div class="opr-btns">
        <!-- 分页组件 -->
        <el-pagination
            class="pg"
            @current-change="pageChangeHandle"
            :current-page.sync="currentPage"
            :pager-count="5"
            :page-size="30"
            layout="prev, pager, next"
            :total="totalRows">
        </el-pagination>
      </div>
    </div>
    <!-- 数据分析对话框组件 -->
    <DataAnalysisDialog ref="dataAnalysisDialog"></DataAnalysisDialog>
  </div>
</template>
<script>
// 引入 Leaflet 和其他依赖
import L from 'leaflet'
import 'leaflet.markercluster/dist/MarkerCluster.css';
import 'leaflet.markercluster/dist/MarkerCluster.Default.css';
import 'leaflet.markercluster';
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css';
import 'leaflet-draw/dist/leaflet.draw';
import 'leaflet-canvas-layer';
import leafletImage from 'leaflet-image';
import html2canvas from 'html2canvas';

import domtoimage from 'dom-to-image';
// import "../../../public/js/leaflet.draw-cn.js";

// 引入其他组件和 API
import BaseMapSwitch from "@/components/visualized/BaseMapSwitch.vue";
import WmtsCatalogTree from "@/components/visualized/WmtsCatalogTree.vue";
import SearchPanel from "@/components/visualized/SearchPanel.vue";
import SampleList from "@/components/visualized/SampleList.vue";
import ClusterLayer from "@/views/visualized/ClusterLayer";
import {searchByCondition, searchByConditionData} from "@/api/sampleAPI";
import DrawRectTool from "@/views/visualized/DrawRectTool";
import DrawPolygonTool from "@/views/visualized/DrawPolygonTool";
import TimeLine from "@/components/TimeLine"
import SearchResultLayer from "@/views/visualized/SearchResultLayer";
import DataAnalysisDialog from './components/DataAnalysisDialog.vue'
// 创建地图图层对象
const allPointLayer = new ClusterLayer()
const resultPointLayer = new SearchResultLayer()
let map = null;
const drawnItems = new L.FeatureGroup();
var drawTool;
export default {
  name: "Index",
  components: {
    // 导入并注册基础地图开关组件
    BaseMapSwitch,
    // 导入并注册WMTS图层目录树组件
    WmtsCatalogTree,
    // 导入并注册搜索面板组件
    SearchPanel,
    // 导入并注册样品列表组件
    SampleList,
    // 导入并注册时间轴组件
    TimeLine,
    // 导入并注册数据分析对话框组件
    DataAnalysisDialog
  },
  data() {
    // 组件内部的数据状态
    return {
      // 控制搜索面板显示与隐藏
      isShowSearch: false,
      // 控制搜索结果面板显示与隐藏
      isShowSearchResult: false,
      // 当前页码
      currentPage: 1,
      // 分页加载状态
      loading: false,
      // 总行数
      totalRows: 0,
      // 控制地图图层菜单显示与隐藏
      mapLayerMenu: false,
      // 控制时间轴菜单显示与隐藏
      timeLineMenu: false,
      // 当前搜索条件
      currentCondition: {},
      // 用于存储绘制图形的GeoJSON字符串
      geojsonStr: '',
      // 搜索结果数据
      resultData: [],
      map: null  // 确保 map 定义在这里
      
    };
  },

  methods: {
     downloadMap() {
    const mapContainer = this.$el.querySelector('#map');

    // 确保所有图层已经加载完毕
    setTimeout(() => {
    html2canvas(mapContainer, {
      useCORS: true,  // 允许跨域资源
      allowTaint: false,  // 不允许跨域资源污染画布
      logging: true,  // 开启日志
      scale: 2  // 提高图像质量
    }).then(canvas => {
      const dataUrl = canvas.toDataURL('image/png');
      const link = document.createElement('a');
      link.download = 'map.png';
      link.href = dataUrl;
      link.click();
    }).catch(error => {
      console.error('Error during html2canvas', error);
    });
    }, 500); // 添加延迟，确保所有图层加载完毕
  
  },
    gotoAncientMap() {
      const data = {
        "id": 32,
        "ename": "Holocene",
        "name": "全新世",
        "level": 4,
        "parentId": 12,
        "color": "#FEF2E0",
        "end": 0,
        "start": 0.0117,
        "children": [
          {
            "id": 3004,
            "ename": "Meghalayan",
            "name": "梅加拉亚期",
            "level": 5,
            "leaf": true,
            "parentId": 32,
            "color": "#FDEDEC",
            "end": 0,
            "start": 0.0042,
            "children": []
          },
          {
            "id": 3003,
            "ename": "Northgrippian",
            "name": "诺斯格瑞比期",
            "level": 5,
            "leaf": true,
            "parentId": 32,
            "color": "#FDECE4",
            "end": 0.0042,
            "start": 0.008,
            "children": []
          },
          {
            "id": 3002,
            "ename": "Greenlandian",
            "name": "格陵兰期",
            "level": 5,
            "leaf": true,
            "parentId": 32,
            "color": "#FEECDB",
            "end": 0.0082,
            "start": 0.0117,
            "children": []
          }
        ]
      }
      this.$router.push({
        path: 'ancient-maps',
        query: {
          url: encodeURIComponent(`http://onelab.ac.cn/paleo/${this.objectToUrlParams(data)}`)
        }
      });
    },
    // 地质图层菜单点击事件
    layerClick() {
      this.mapLayerMenu = !this.mapLayerMenu;
      if (this.timeLineMenu && this.mapLayerMenu) {
        this.timeLineMenu = false;
      }
    },
    // 时间轴菜单点击事件
    timelineClick() {
      this.timeLineMenu = !this.timeLineMenu;
      if (this.mapLayerMenu && this.timeLineMenu) {
        this.mapLayerMenu = false;
      }
    },
    // 分页切换事件处理
    async pageChangeHandle() {
      this.loading = true;
      try {
        // 设置每页数据数量
        this.currentCondition.pageSize = 20;
        // 调用搜索方法
        const res = await searchByConditionData(this.currentCondition);
        // 更新搜索结果数据
        this.resultData = res.data.results;
        // 更新总行数
        this.totalRows = res.data.total;
      } catch (e) {
        console.log(e);
      } finally {
        this.loading = false;
      }
    },
    // 将对象转换为URL参数字符串
    objectToUrlParams(obj) {
      const params = [];
      for (const key in obj) {
        if (obj.hasOwnProperty(key)) {
          params.push(`${encodeURIComponent(key)}=${encodeURIComponent(obj[key])}`);
        }
      }
      return `?${params.join('&')}`;
    },
    // 处理时间轴点击事件
    handleTimeLineClick(data) {
      console.log(data);
      // 路由导航至另一页面，传递参数
      this.$router.push({
        path: 'ancient-maps',
        query: {
          url: encodeURIComponent(`http://onelab.ac.cn/paleo/${this.objectToUrlParams(data)}`)
        }
      });
    },
    // 删除图层
    deleteLayers() {
      map.removeLayer(drawnItems);
    },
    // 重命名为 onSearchSuccess_map，只对图形进行查询
    async onSearchSuccess_map(condition) {
      {

        if (condition.ACondition.rangeGeoJson) {
          // 原始代码
// const latlngs = condition.ACondition.rangeGeoJson[0];

// 修改后的代码
          const rangeGeoJson = condition.ACondition.rangeGeoJson;

          if (rangeGeoJson && rangeGeoJson.length > 0) {
            const latlngs = rangeGeoJson[0];

            // 其余的代码继续执行
            const geoJson = {
              type: "Feature",
              properties: {},
              geometry: {
                type: "Polygon",
                coordinates: [latlngs.map(latlng => [latlng.lng, latlng.lat])]
              }
            };


            // 获取多边形坐标
            const coordinates = geoJson.geometry.coordinates[0];

// 检查多边形是否闭合
            const isClosed = coordinates[0][0] === coordinates[coordinates.length - 1][0] &&
                coordinates[0][1] === coordinates[coordinates.length - 1][1];

// 如果多边形未闭合，则添加一个与第一个坐标相同的点
            if (!isClosed) {
              coordinates.push(coordinates[0]);
            }


            const geoJsonString = geoJson;

            condition.ACondition.rangeGeoJson = geoJsonString;
          } else {
            // 处理 rangeGeoJson 未定义或为空的情况
            console.error("rangeGeoJson is undefined or empty.");
          }


        }


      }


      // 构建POST请求数据
      const postData = {
        rangeGeoJson: condition.ACondition.rangeGeoJson ? JSON.stringify(condition.ACondition.rangeGeoJson) : null,
        pageNo: this.currentPage,
        pageSize: 20
        // 其他条件可以根据需要添加
      };

      // 更新当前搜索条件
      this.currentCondition = postData;
      // 更新搜索结果图层条件
      resultPointLayer.updateCondition(postData);
      // 控制搜索面板和搜索结果面板显示和隐藏
      this.isShowSearch = false;
      this.isShowSearchResult = true;
      // 重置当前页码
      this.currentPage = 1;
      // 触发分页切换事件
      await this.pageChangeHandle();
    },


    // 处理搜索成功事件
    async onSearchSuccess(condition) {
      // 如果绘图工具存在，将绘制的几何图形加入搜索条件
      if (drawTool) {
        condition.ACondition.rangeGeoJson = drawTool.getResultAsGeoJSON();
      }
      const {
        dPrincipalElementFilters,
        dMicronutrientFilters,
        dicpmsFilters,
        dOtopesFilters,
        ACondition,
        BCondition
      } = condition;
      const {
        rangeGeoJson,
        detailed_locality,
        terrane,
        rock_or_stratigraphic_unit,
        geological_period,
        lithology,
        object_or_mineral_determined,
        ageStart,
        ageEnd
      } = ACondition;
      const {
        sampleName,
        reference,
        lithology_subdivision,
        metamorphic_grade,
        geodynamic_settings,
        expression,
        interpretation
      } = BCondition;
      // 构建POST请求数据
      const postData = {
        rangeGeoJson: rangeGeoJson ? JSON.stringify(rangeGeoJson) : null,
        detailed_locality,
        terrane,
        rock_or_stratigraphic_unit,
        geological_period,
        lithology,
        object_or_mineral_determined,
        ageStart: ageStart ? ageStart : null,
        ageEnd: ageEnd ? ageEnd : null,
        sampleName,
        reference,
        lithology_subdivision,
        metamorphic_grade,
        geodynamic_settings,
        expression,
        interpretation,
        dPrincipalElementFilters: this.transFilter(dPrincipalElementFilters),
        dMicronutrientFilters: this.transFilter(dMicronutrientFilters),
        dicpmsFilters: this.transFilter(dicpmsFilters),
        dOtopesFilters: this.transFilter(dOtopesFilters),
        pageNo: this.currentPage,
        pageSize: 20
      };

      // 更新当前搜索条件
      this.currentCondition = postData;
      // 更新搜索结果图层条件
      resultPointLayer.updateCondition(postData);
      // 控制搜索面板和搜索结果面板显示和隐藏
      this.isShowSearch = false;
      this.isShowSearchResult = true;
      // 重置当前页码
      this.currentPage = 1;
      // 触发分页切换事件
      await this.pageChangeHandle();
    },
    // 转换过滤器数据格式
    transFilter(filters) {
      if (!filters) {
        return null;
      }
      if (filters.length == 0) {
        return null;
      }
      const newFilters = filters.map(({name, minVal, maxVal}) => ({
        factor: name,
        min: Number(minVal),
        max: Number(maxVal)
      }));
      return newFilters;
    },
    // 绘制矩形
    doDrawRect() {
      if (drawTool) {
        drawTool.clear();
      }
      drawTool = new DrawRectTool(map);
      drawTool.startDrawing();
    },
    // 绘制多边形
    doDrawPolygon() {
      if (drawTool) {
        drawTool.clear();
      }
      drawTool = new DrawPolygonTool(map);
      drawTool.startDrawing();
    },
    // 清除绘制图形
    doClear() {
      drawTool.clear();
    },
    // 点击列表项事件处理
    onItemClick(item) {
      const basicInfo = item;
      // 如果有坐标信息，创建自定义弹窗
      if (item.longitude && item.latitude) {
        var popupContent = `
          <div class="my-popup">
            <ul>
              <li>样品名称:<span>${basicInfo.sample_Name}</span></li>
              <li>年龄值(Ma):<span>${basicInfo.age}</span></li>
              <li>岩性小类:<span>${basicInfo.lithology_subdivision}</span></li>
              <li>岩石或地层单元:<span>${basicInfo.rock_or_stratigraphic_unit}</span></li>
              <li>测定对象:<span>${basicInfo.object_or_mineral_determined}</span></li>
            </ul>
            <!-- 添加样品详细信息链接 -->
            <a href="/#/search/result-detail/sample?sampleId=${item.sample_ID}" target="_blank">详细信息</a>
          </div>
        `;
        // 更新弹出框的内容并显示
        this.popup.setLatLng([item.latitude, item.longitude])
            .setContent(popupContent)
            .openOn(map);
        // 创建自定义弹窗，并在地图上打开
        var customPopup = L.popup().setContent(popupContent);
        customPopup.setLatLng([item.latitude, item.longitude]).openOn(map);
        map.setView([item.latitude, item.longitude], 11);
      } else {
        // 如果无坐标信息，弹出提示
        alert("该样品无坐标信息");
      }
    },
    // 将地图边界转换为GeoJSON对象
    boundsToGeoJSON(bounds) {
      var polygon = {
        type: "Feature",
        properties: {},
        geometry: {
          type: "Polygon",
          coordinates: [
            [
              [bounds.getWest(), bounds.getNorth()],
              [bounds.getEast(), bounds.getNorth()],
              [bounds.getEast(), bounds.getSouth()],
              [bounds.getWest(), bounds.getSouth()],
              [bounds.getWest(), bounds.getNorth()]
            ]
          ]
        }
      };
      return polygon;
    },
    // 数据分析点击事件处理
    onDataAnalysis: function () {
      // 如果数据分析对话框存在，打开对话框
      if (!this.$refs.dataAnalysisDialog) {
        return;
      }
      // 获取搜索结果中的样品ID，并去重
      let sampleIds = this.resultData.map(item => item.sample_ID);
      sampleIds = Array.from(new Set(sampleIds));
      // this.$refs.dataAnalysisDialog.open(sampleIds);
      // this.$router.push(`/visualized/data-analysis?sampleIds=${sampleIds.join(',')}`)
      let routeData = this.$router.resolve({
        path: `/visualized/data-analysis?sampleIds=${sampleIds.join(',')}`
      });
      window.open(routeData.href, "_blank");
    }
  },
  watch: {
    // 监听搜索结果面板显示状态的变化
    isShowSearchResult() {
      if (this.isShowSearchResult) {

        // 在地图上移除所有点图层，添加搜索结果点图层
        map.removeLayer(allPointLayer);
        map.addLayer(resultPointLayer);


      } else {
        console.log("删除图层1");

        map.removeLayer(drawnItems);

        // 在地图上添加所有点图层，移除搜索结果点图层
        map.addLayer(allPointLayer);
        map.removeLayer(resultPointLayer);
        // 如果绘图工具存在，清除绘制的图形

        if (drawTool) {
          drawTool.clear();
        }
      }
    },

  },
  mounted() {
    // 初始化Leaflet地图对象
    map = L.map('map', {
      crs: L.CRS.EPSG4326,
      zoom: 4,
      center: [35.8374, 104.28225],
      zoomControl: false,
      attributionControl: false
    });


    // 创建空的弹出框，并将其绑定到地图对象
    this.popup = L.popup();
    map.on('click', () => {
      this.popup.remove();
    });

    // 设置地图切换组件的地图对象
    this.$refs.mapswitchRef.setMap(map);

    // 添加所有点图层到地图
    map.addLayer(allPointLayer);

    // 设置图层目录树组件的地图对象
    this.$refs.catalogTreeRef.setMap(map);

    // 添加比例尺控件到地图
    L.control
        .scale({
          maxWidth: 240,
          metric: true,
          imperial: false,
          position: "bottomleft"
        })
        .addTo(map);

    // 添加缩放控件到地图
    L.control.zoom({position: "bottomleft"}).addTo(map);


    const drawControl = new L.Control.Draw({
      draw: {
        rectangle: true,
        polygon: true,
        polyline: false,
        circle: false,
        circlemarker: false,  // 禁用 circlemarker
        marker: false
      },
      edit: {
        featureGroup: drawnItems,
        remove: true, // 允许删除图形
        edit: false,  // 不允许编辑图形
      },

      delete: true  // 显示 "Clear all layers" 按钮
    });


    map.addControl(drawControl);


    map.on(L.Draw.Event.CREATED, (event) => {
      const layer = event.layer;
      drawnItems.addLayer(layer);
      map.addLayer(drawnItems);

      // 获取绘制的图形的坐标信息
      const latlngs = layer.getLatLngs();

      // 调用 onSearchSuccess 方法并传递坐标信息
      this.onSearchSuccess_map({
        ACondition: {
          // 在这里设置其他条件，如坐标信息
          rangeGeoJson: latlngs, // 这取决于您的数据结构
          // ...其他条件...
        },
        // ...其他条件...
      });

      // 将地图视图调整为包含所有绘制的图形
      // map.fitBounds(drawnItems.getBounds());
    });


// 添加绘制完成事件监听器
    map.on('draw:created', function (event) {
      const layer = event.layer;

      // 在绘制新图形之前清除之前的图层
      drawnItems.clearLayers();

      // 添加新图层到图层组
      drawnItems.addLayer(layer);

      // 在这里可以执行其他操作，例如获取绘制的坐标信息等
    });


// 添加点击事件监听器到删除按钮
    map.on('draw:deleted', function (event) {
      // 使用 clearLayers 方法清除所有图层
      drawnItems.clearLayers();
    });
    // 在这里访问 drawnItems，并将其赋给 this.layerGroup
    this.layerGroup = this.$parent.drawnItems;

  }
}


</script>
<style>
@keyframes fadeInUp {
  from {
    opacity: 0;

  }
  to {
    opacity: 1;

  }
}

.text-label {
  animation: fadeInUp 1s ease-in-out;
  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: center; /* 垂直居中 */
  border-radius: 50%;
  text-align: center;
  font-size: 16px;

}

.circle-color-1 {
  background-color: rgba(244, 152, 65, 0.82);
  border: 5px solid rgba(255, 255, 254, 0.79);
  color: #2e2e2d;
}

.circle-color-2 {
  background-color: rgba(248, 196, 40, 0.76);
  border: 5px solid rgba(249, 249, 247, 0.79);
  color: #1c1c1c;
}

.circle-color-3 {
  background-color: rgba(163, 193, 98, 0.8);
  border: 5px solid rgba(252, 251, 249, 0.79);
  color: #161616;
}

.circle-color-4 {
  background-color: rgba(244, 152, 65, 0.82);
  border: 5px solid rgba(252, 251, 249, 0.79);
  color: #2e2e2d;
}

.text-label-single {

  display: flex;
  justify-content: center; /* 水平居中 */
  align-items: flex-end; /* 垂直居中 */
  white-space: nowrap;
  background-color: rgba(75, 136, 251, 0.74);
  border: 5px solid rgba(75, 136, 251, 0.86);
  border-radius: 50%;
  text-align: center;
  font-size: 14px;
  color: rgb(18, 110, 230);
  text-shadow: 1px 1px 1px #ffffff;
  padding-top: 10px;
  margin-top: -50px;
}

.text-label-single {
  background-color: rgba(255, 0, 0, 0.84);
  border: 2px solid #e4e2e2;


}

.text-label:hover {
  border: 2px solid rgba(241, 238, 237, 0.86);
  background-color: rgba(227, 84, 44, 0.74);
}
</style>
<style lang="less" scoped>
.base-map-container {

  position: absolute;
  height: 100px;
  bottom: 20px;
  right: 20px;
  z-index: 2;
}

.m-pg-sub {
  position: absolute;
  top: 70px;
  left: 10px;
  right: 10px;
  background: #c0bdbd;
  bottom: 10px;
  z-index: 1;
  border-radius: 5px;
  box-shadow: 1px 2px #ddd;
  border: 1px solid #2196f3;
}

.search-result-panel {
  position: absolute;
  left: 20px;
  top: 80px;
  background: #fff;
  bottom: 40px;
  width: 400px;
  z-index: 10000;
  border: 1px solid #32609e;
  display: flex;
  flex-direction: column;
}

.search-result-panel .list-warapper {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
}

.search-result-panel .header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #2b85e4;
  color: #fff;
  padding: 10px;

  &-r {
    display: flex;
    align-items: center;

    .el-button {
      margin-right: 8px;
    }
  }
}

.search-result-panel /deep/ .el-card__header {
  padding: 10px 10px;
  background: #32609e;
  color: #fff;
}

.search-result-panel /deep/ .el-card__body, .el-main {
  padding: 0;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.opr-btns {
  background: #f0f0f0;
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid #ddd;
  justify-content: center;
}

.opr-btns .pg {
  flex: 1;
  padding: 0px;
}

.box-card {
  position: absolute;
  right: 20px;
  top: 80px;
  bottom: 150px;
  z-index: 10;
  display: flex;
  flex-direction: row-reverse;
}

.box-card .cos-menu {
  margin-left: 10px;
  display: inline-block;
  flex-direction: column;
  list-style: none;
  padding: 0;
  align-self: flex-start;
}

.time-line-card /deep/ .el-card__body {
  flex-grow: 1;
  overflow-y: scroll;
  padding: 5px;
  overflow-y: auto; /* 启用垂直滚动条 */
}

.box-card .cos-menu li:first-child {
  border-bottom: 1px dotted #fff;
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}

.box-card .cos-menu li:last-child {

  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}

.box-card .cos-menu li {
  writing-mode: vertical-rl;
  text-orientation: upright;
  padding: 10px; /* 可选：添加内边距 */
  margin: 0; /* 可选：添加外边距 */
  background: rgba(37, 93, 189, 0.91);
  color: #fff;
  cursor: pointer;
  box-shadow: 1px 1px 1px rgba(4, 24, 56, 0.98);
  letter-spacing: 0.2em;
}

.box-card .cos-menu li:hover {
  background: rgba(28, 79, 166, 0.96);
}

.box-card /deep/ .el-card {
  border: 1px solid #32609e;

}

.box-card /deep/ .el-card__header {
  padding: 10px 10px;
  background: #32609e;
  color: #fff;
}

.search-tool {
  padding: 0 10px;
  position: absolute;
  top: 70px;
  left: 10px;
  background: #fff;
  border-radius: 5px;
  border: 2px solid #ddd;
  z-index: 2;
}

.search-card {
  width: auto;
  height: auto;
  z-index: 1000;
}

//.search-card{
//  position: absolute;
//  top: 110px;
//  bottom: 100px;
//  left: 400px;
//  right: 400px;
//  z-index: 10;
//}

.search-warpper {
  display: flex;
  justify-content: center; /* 将内容水平居中 */
  align-items: center; /* 将内容垂直居中 */
  height: calc(100vh - 80px);
  margin: 0; /* 清除默认的外边距 */
}

.search-btn {
  position: relative;
  left: 80px;
  top: 65px;
  z-index: 10000;
}

div {
  moz-user-select: -moz-none;
  -moz-user-select: none;
  -o-user-select: none;
  -khtml-user-select: none;
  -webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

// 地质图层card
.el-card--dztc {
  display: flex;
  flex-direction: column;

  /deep/ .el-card__header {
    flex: 0 0 auto;
  }

  /deep/ .el-card__body {
    flex: 1 1 auto;
    height: 0;
    overflow: auto;
  }
}
</style>
<style>
.leaflet-popup-content-wrapper {
  border-radius: 3px;
}

.my-popup {
  min-width: 180px;
  padding: 10px;
  display: flex;
  flex-direction: column;
}

.my-popup ul li {
  font-size: 15px;
  margin: 4px 10px;
  font-weight: bold;
}

.my-popup ul li span {
  color: midnightblue;
  font-size: 16px;
}

.my-popup a {
  margin-left: auto;
}

.time-line {
  position: absolute;
  bottom: 0;
  z-index: 100000;
  background: rgba(255, 255, 255, 0.89);
  width: 100%;
  margin-right: auto;
  overflow: hidden;
  padding: 10px;
  border: 1px solid #ddd;
  display: none;

}
</style>
