import L from 'leaflet';

function DrawRectTool(map) {
  this.map = map;
  this.rectangleLayer = null;
  this.corner1 = null;
  this.corner2 = null;
}

DrawRectTool.prototype.startDrawing = function() {
  var self = this;

  function startDrawingRectangle(e) {
    if (!e) return
    self.corner1 = [e.latlng.lat, e.latlng.lng];
    self.corner2 = null; // 在开始新的矩形绘制时重置corner2

    self.map.on('mousemove', updateRectangle);
    self.map.on('click', endDrawingRectangle);
    self.map.off('click', startDrawingRectangle);
  }

  function updateRectangle(e) {
    if (!e) return
    if (self.corner1) {
      self.corner2 = [e.latlng.lat, e.latlng.lng];
      self.drawRectangle();
    }
  }

  function endDrawingRectangle(e) {
    self.corner2 = [e.latlng.lat, e.latlng.lng];
    self.map.off('mousemove', updateRectangle);
    self.map.off('click', endDrawingRectangle);
  }

  self.map.on('click', startDrawingRectangle);
};

DrawRectTool.prototype.drawRectangle = function() {
  if (this.corner1 && this.corner2) {
    var bounds = new L.LatLngBounds(this.corner1, this.corner2);

    if (this.rectangleLayer) {
      this.map.removeLayer(this.rectangleLayer);
    }

    this.rectangleLayer = L.rectangle(bounds, { color: 'rgba(33,237,210,0.65)', weight: 2 }).addTo(this.map);
  }
};

DrawRectTool.prototype.clear = function() {
  if (this.rectangleLayer) {
    this.map.removeLayer(this.rectangleLayer);
    this.rectangleLayer = null;
    this.corner1 = null;
    this.corner2 = null;
  }
};

DrawRectTool.prototype.getResultAsGeoJSON = function() {
  if (this.corner1 && this.corner2) {
    var polygon = {
      type: "Feature",
      properties: {},
      geometry: {
        type: "Polygon",
        coordinates: [
          [
            [this.corner1[1], this.corner1[0]],
            [this.corner2[1], this.corner1[0]],
            [this.corner2[1], this.corner2[0]],
            [this.corner1[1], this.corner2[0]],
            [this.corner1[1], this.corner1[0]]
          ]
        ]
      }
    };
    return polygon;
  } else {
    return null;
  }
};

export default DrawRectTool;
