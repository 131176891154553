function convertTreeDataInner(serverData, parentId) {
  const children = [];

  serverData.forEach((item) => {
    if (item.parentid === parentId) {
      const node = {
        key: item.id,
        label: item.name,
        wmsurl: item.wmsurl,
        serviceType: item.dept_id
      };
      const subChildren = convertTreeDataInner(serverData, item.id);
      if (subChildren.length > 0) {
        node.children = subChildren;
      }
      children.push(node);
    }
  });

  return children;
}

export function convertTreeData(serverData, parentId) {
  return convertTreeDataInner(serverData, parentId)
}
