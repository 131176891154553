<template>
  <div>
    <h4 style="color:#000000;font-size: 15px; font-weight: bold;">
      <i class="el-icon-s-operation"></i><span style="display:inline-block;width: 80px; font-weight: bold;">{{ props.name}}</span>
      <el-popover
        placement="right"

        width="500"
        trigger="manual"
        v-model="visible">

      <el-row>
        <el-col :span="12">
          <el-select placeholder="请选择元素" size="mini" v-model="currentEle">
            <el-option-group
                v-for="group in props.options"
                :key="group.label"
                :label="group.label">
              <el-option
                  v-for="item in group.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">

              </el-option>
            </el-option-group>
          </el-select>
        </el-col>
        <el-col :span="5">
          <el-input v-model="minVal" placeholder="最小值" size="mini"></el-input>
        </el-col>
        <el-col :span="1">至</el-col>
        <el-col :span="5">
          <el-input v-model="maxVal" placeholder="最大值" size="mini"></el-input>
        </el-col>

      </el-row>
      <el-row style="margin-top: 10px;">

        <el-col :span="8" style="float: right;">
          <el-button  size="mini" type="primary" @click="addHandle">确定</el-button>
          <el-button  size="mini" type="danger" @click="visible = !visible">取消</el-button>
        </el-col>
      </el-row>

      <el-button class="buttons"  size="mini" type="text" slot="reference" @click="visible = !visible">添加条件</el-button>
    </el-popover>
      <el-button class="buttons" size="mini" type="text" style="color: #dd6161;" @click="clearCondition">清除</el-button></h4>
    <hr>
    <div class="conditions-list">
      <div class="condition-list-group-item" v-for="groupItem in Object.keys(groupConditions)">
        <span class="title">{{groupItem}}</span>
        <el-tag v-for="(item,index) in groupConditions[groupItem]" size="mini" closable @close="doDelete(index)">{{item.name}}{{  `: ${item.minVal}~${item.maxVal}` }}</el-tag>
      </div>

    </div>

  </div>

</template>

<script setup>
import { ref, watch, computed, defineProps } from "vue";
const props = defineProps(['name','options','value'])
const consitions = ref(props.value)
const currentEle = ref("")
const minVal = ref(0)
const maxVal = ref(null)
const visible = ref(false)
watch(() => props.value, (newValue) => {
  consitions.value = { ...newValue };
});

const selectGroup = computed(() => {
  const selectedValue = currentEle.value;
  for (const group of props.options) {
    const selectedOption = group.options.find((item) => item.value === selectedValue);
    if (selectedOption) {
      return group.label;
    }
  }
  return null;
})

const groupConditions = computed(() => {
  return groupBy(consitions.value, 'group');
})
function groupBy(arr, property) {
  return arr.reduce((acc, obj) => {
    const key = obj[property];
    if (!acc[key]) {
      acc[key] = [];
    }
    acc[key].push(obj);
    return acc;
  }, {});
}

function addHandle() {
  visible.value = false
  consitions.value.push({
    name: currentEle.value,
    minVal: minVal.value,
    maxVal: maxVal.value,
    group: selectGroup.value
  })
}

function clearCondition() {
  consitions.value = []
}

function doDelete(index) {
  consitions.value.splice(index, 1)
}
</script>

<style scoped>
.buttons{
  margin-left: 10px;
}
hr{
  margin: 2px 0;
}

.conditions-list .el-tag{
  margin: 3px;
}
.condition-list-group-item{
  margin: 3px 0 3px 8px;
  display: block;
  padding: 2px;
  background: #e5f1f9;

}
.condition-list-group-item .title{
  display: block;
  font-size: 13px;
  color: #5f5f5f;
  font-weight: bold;
}

</style>
