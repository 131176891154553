<!--
 * @Author: lg9527 13691080728@126.com
 * @Date: 2023-09-17 09:51:48
 * @LastEditors: lg9527 13691080728@126.com
 * @LastEditTime: 2023-11-08 15:06:35
 * @FilePath: \zky_webVue\src\components\visualized\SampleList.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <ul>
    <!--查询列表-->
    <li :key="item.sample_Name" class="list-item" v-for="item in resultData" @click="handleCilck(item)">
      <h4><i class="el-icon-aim"></i>{{ item.sample_Name }} <a style="color: #2196f3;float: right;" :href="`/#/search/result-detail/sample?sampleId=${item.sample_ID}`" target="_blank">详细信息</a></h4>
      <!-- <div>地址:<span>{{ item.place_for_experiment }}</span></div> -->
      <div>年龄值(Ma):<span>{{ item.age }}</span></div>
      <div>岩性小类:<span>{{ item.lithology_subdivision }}</span></div>
      <div>岩石或者地层单元:<span>{{ item.rock_or_stratigraphic_unit }}</span></div>
      <div>测定对象:<span>{{ item.object_or_mineral_determined }}</span></div>
    </li>
  </ul>
</template>

<script setup>
import { defineProps } from "vue";
const { resultData } = defineProps(['resultData'])
const emit = defineEmits(['itemClick'])

function handleCilck(item) {
  emit('itemClick', item)
}
</script>

<style scoped>
li{
  margin: 10px;
  border-bottom: 1px solid #ddd;
}

ul{

  overflow-y: scroll;
}
.list-item span{
  color: #0d2e84;
}
</style>
