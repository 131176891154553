<template>
  <el-card shadow="always" class="my-card" v-drag="{ handle: '#searchHeader' }">
    <div slot="header" id="searchHeader">
      <span style="font-weight: bold;">样品检索</span><i @click="closeThis()" style="float: right;" class="close-btn el-icon-close"></i>
    </div>
    <div >
      <div class="search-panel-content" >
        <h4 style="color:#000000;font-size: 15px; font-weight: bold;"> <i class="el-icon-s-operation"></i>空间范围</h4>
        <hr>
        <el-button type="primary" size="mini" @click="handleDrawRect">矩形范围</el-button>
        <el-button type="primary" size="mini" @click="handleDrawPolygon">多边形范围</el-button>

        <el-button type="text" @click="handleClear">清除</el-button>
        <h4 style="color:#000000;font-size: 15px; font-weight: bold;"> <i class="el-icon-s-operation"></i>常用</h4>
        <hr>
        <BasicCondition :condition="ACondition" ></BasicCondition>
        <h4 style="color:#000000;font-size: 15px; font-weight: bold;">
          <i class="el-icon-s-operation"></i>不常用<i :class="showMore?'el-icon-arrow-up':'el-icon-arrow-right'" @click="showMore = !showMore">
        </i></h4>
        <hr>
        <MoreCondition :condition="BCondition"  v-show="showMore"></MoreCondition>
        <condition-select name="主量元素" :options="optionsZhuliang" v-model="dPrincipalElementFilters"></condition-select>
        <condition-select name="微量元素" :options="optionsWeiLiang" v-model="dMicronutrientFilters"></condition-select>
        <condition-select name="定年分析" :options="optionsDingNian" v-model="dicpmsFilters" ></condition-select>
        <condition-select name="同位素分析" :options="optionsTongweiSu" v-model="dOtopesFilters"></condition-select>
      </div>
      <div class="opr-btns">
        <el-button size="small" type="primary" @click="doSearch">检索</el-button>
        <!-- <el-button size="small" @click="resetAllCondition">重置所有</el-button> -->
        <el-button size="small" @click="resetAttCondition">重置属性</el-button>
        <el-popover
          v-model="saveConditionPopupVisble"
            placement="top-start"
            title="名称"
            trigger="click">
          <el-input v-model="conditionName" size="small" placeholder="保存条件名称"></el-input>
          <el-button type="primary" size="small" @click="saveCondition">确定</el-button>
          <el-button slot="reference" size="small">保存条件</el-button>
        </el-popover>
        <el-popover
            v-model="loadConditionPopupVisble"
            placement="bottom-start"
            title="历史条件"
            trigger="click">
          <ul >
            <li class="hist-condition-item" v-for="item in histConditionList" @click="loadCondition(item)">{{item.sname}} <i @click="deleteCondition(item)" style="float: right;color: #ff0000;" class="el-icon-delete"></i></li>
          </ul>
          <el-button slot="reference" type="primary" size="small">载入条件</el-button>
        </el-popover>

      </div>
    </div>

  </el-card>
</template>

<script setup>
import { ref, defineEmits, watch } from "vue";
import { Message } from 'element-ui';

import ConditionSelect from "@/components/visualized/condition-select.vue";
import { DingNianFields, TongweiSuFields, WeiLiangFields, ZhuLiangFields } from "@/views/visualized/FilterFields";
import MoreCondition from "@/components/visualized/MoreCondition.vue";
import BasicCondition from "@/components/visualized/BasicCondition.vue";
import { deleteConditionRequest, getConditionListRequest, saveConditionRequest } from "@/api/sampleAPI";

const showMore = ref(false)
const loading = ref(false)
const conditionName = ref("")
const saveConditionPopupVisble = ref(false)
const loadConditionPopupVisble = ref(false)
const spatialType = ref("矩形范围")
const emit = defineEmits(['close', 'success', 'DrawRect', 'DrawPolygon', 'clear'])
const optionsZhuliang = ref(ZhuLiangFields)
const optionsWeiLiang = ref(WeiLiangFields)
const optionsDingNian = ref(DingNianFields)
const optionsTongweiSu = ref(TongweiSuFields)

const searchCache = localStorage.getItem("SearchCache")
const searchCacheObj = JSON.parse(searchCache)


const dPrincipalElementFilters = ref([])
const dMicronutrientFilters = ref([])
const dicpmsFilters = ref([])
const dOtopesFilters = ref([])

const spatialGeojson = ref("")
const ACondition = ref({
})
const BCondition = ref({
})
if (searchCacheObj){
  if (searchCacheObj.ACondition){
    ACondition.value = searchCacheObj.ACondition
  }
  if (searchCacheObj.BCondition){
    BCondition.value = searchCacheObj.BCondition
  }
  if (searchCacheObj.dprincipalElementFilters){
    dPrincipalElementFilters.value = searchCacheObj.dprincipalElementFilters
  }
  if (searchCacheObj.dMicronutrientFilters){
    dMicronutrientFilters.value = searchCacheObj.dMicronutrientFilters
  }
  if (searchCacheObj.dicpmsFilters){
    dicpmsFilters.value = searchCacheObj.dicpmsFilters
  }
  if (searchCacheObj.dOtopesFilters){
    dOtopesFilters.value = searchCacheObj.dOtopesFilters
  }
}


const histConditionList = ref([])

watch(loadConditionPopupVisble, async () => {
  await refreshHist()
  console.log(histConditionList.value)
})

async function refreshHist() {
  const res = await getConditionListRequest()
  histConditionList.value = res.data
}

function resetAttCondition() {
  dPrincipalElementFilters.value = []
  dMicronutrientFilters.value = []
  dicpmsFilters.value = []
  dOtopesFilters.value = []
  ACondition.value = {}
  BCondition.value = {}
  localStorage.setItem("SearchCache", JSON.stringify(null))
 }

function resetAllCondition() {
  resetAttCondition()
  spatialGeojson.value = ""
  
}

function closeThis() {
  emit('close')
}

async function doSearch() {
  const condition = {
    dPrincipalElementFilters: dPrincipalElementFilters.value,
    dMicronutrientFilters: dMicronutrientFilters.value,
    dicpmsFilters: dicpmsFilters.value,
    dOtopesFilters: dOtopesFilters.value,
    ACondition: ACondition.value,
    BCondition: BCondition.value
  }
  localStorage.setItem("SearchCache", JSON.stringify(condition))
  emit('success', condition)
}

async function saveCondition() {
  const condition = {
    dPrincipalElementFilters: dPrincipalElementFilters.value,
    dMicronutrientFilters: dMicronutrientFilters.value,
    dicpmsFilters: dicpmsFilters.value,
    dOtopesFilters: dOtopesFilters.value,
    ACondition: ACondition.value,
    BCondition: BCondition.value
  }
  const str = JSON.stringify(condition)
  try {
    const res = await saveConditionRequest(conditionName.value, str)
    saveConditionPopupVisble.value = false
    Message.success('保存成功');
  } catch (err) {
    Message.error('保存失败');
  }
}

function handleDrawRect() {
  emit('DrawRect')
}
function handleDrawPolygon() {
  emit('DrawPolygon')
}

function handleClear() {
  emit('clear')
}

function loadCondition(item) {
  const condition = JSON.parse(item.history)
  dPrincipalElementFilters.value = condition.dPrincipalElementFilters
  dMicronutrientFilters.value = condition.dMicronutrientFilters
  dicpmsFilters.value = condition.dicpmsFilters
  dOtopesFilters.value = condition.dOtopesFilters
  ACondition.value = condition.ACondition
  BCondition.value = condition.BCondition
  loadConditionPopupVisble.value = false
}
async function deleteCondition(item) {
  console.log(item)
  try {
    await deleteConditionRequest(item.sid)
    Message.success('删除成功');
    await refreshHist()
  } catch (err) {
    Message.error('删除失败');
  }
}
</script>

<style scoped>
.search-panel-content{
  max-height: 60vh;

  overflow-y: scroll;
  padding: 20px;
}

.my-card /deep/  .el-card__body, .el-main{
  padding: 0;
}
.my-card /deep/  .el-card__header{
  padding: 10px 10px;
  background: #32609e;
  color: #fff;
}

.my-card{
  border: 1px solid #32609e;
}
.close-btn:hover{
  color: #e3542c;
  cursor: pointer;
  font-weight: bold;
}
.opr-btns{
  background: #f0f0f0;
 padding: 10px;
  display: flex;
  flex-direction: row-reverse;
  border-top: 1px solid #ddd;
  justify-content: center;
}
.opr-btns .el-button{
  margin: 0 10px;
}
.hist-condition-item{
  cursor: pointer;
}
.hist-condition-item:hover{
  color: #2b85e4;
}

</style>
