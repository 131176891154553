<template>
  <div class="dialog-wrap">
    <el-dialog
      title=""
      :visible.sync="visible"
      width="867px"
      @closed="onClosed">
      <div
        v-loading="loading"
        element-loading-text="加载中..."
        class="body">
        <el-row v-if="res" :gutter="20">
          <el-col :span="12" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chat-head">概率累计图</div>
              <div class="chat-body">
                <el-image
                  :src="res.img_gvljt">
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="12" style="margin-bottom: 20px;">
            <div class="chart">
              <div class="chat-head">直方图</div>
              <div class="chat-body">
                <el-image :src="res.img_zft">
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="chart">
              <div class="chat-head">提琴图</div>
              <div class="chat-body">
                <el-image :src="res.img_tqt">
                </el-image>
              </div>
            </div>
          </el-col>
          <el-col :span="12">
            <div class="chart">
              <div class="chat-head">二维图投点</div>
              <div class="chat-body">
                <el-image :src="res.img_ewt">
                </el-image>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
      <span slot="footer" class="dialog-footer">
      </span>
    </el-dialog>
  </div>
</template>

<script>
import * as sampleApi from "@/api/sampleAPI"
import setting from '@/setting'
export default {
  data() {
    return {
      visible: false,
      loading: false,
      res: null
    }
  },
  methods: {
    open: function(sampleIds) {
      // this.getAnalysisImg(sampleIds)
      this.visible = true
    },
    // getAnalysisImg: async function(sampleIds) {
    //   this.loading = true
    //   let data = await sampleApi.getDataAnalysisImg(sampleIds)
    //   .catch(() => {
    //     this.res = null
    //   }).finally(() => {
    //     this.loading = false
    //   })
    //   if (data.msg !== 'ok') {
    //     this.res = null
    //     return
    //   }
    //   let baseUrl = setting.baseUrl.img
    //   data.img_gvljt = data.img_gvljt ? baseUrl + data.img_gvljt : ''
    //   data.img_zft = data.img_zft ? baseUrl + data.img_zft : ''
    //   data.img_ewt = data.img_ewt ? baseUrl + data.img_ewt : ''
    //   data.img_tqt = data.img_tqt ? baseUrl + data.img_tqt : ''
    //   this.res = data
    // },
    onClosed: function() {
      this.res = null
    }
  },
  mounted() {
  }
};
</script>

<style lang="less" scoped>
.dialog-wrap {
  position: relative;
  z-index: 99999;
  .body {
    margin: -20px 0 -40px 0;
    min-height: 300px;
  }
  .chat {
    &-head  {
      margin-bottom: 8px;
    }
    &-body {
      .el-image {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 240px;
        width: 100%;
        i {
          font-size: 80px;
        }
      }
    }
  }
}
</style>
