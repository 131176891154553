/*
 * @Author: lg9527 13691080728@126.com
 * @Date: 2023-09-17 09:51:48
 * @LastEditors: lg9527 13691080728@126.com
 * @LastEditTime: 2023-11-26 09:47:04
 * @FilePath: \zky_webVue\src\api\wmtsCatalog.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */

import request from '@/utils/request'

/**
 * 获取wmts地质图目录
 * @returns {*}
 */
export function getWMTSTree() {
  return request({
    url: '/sys_dict_wms/getPageData',
    method: 'post',
    data: {
      page: 1,
      rows: 120,
      total: 0,
      tableName: "string",
      sort: "string",
      order: "asc",
      wheres: "string",
      export: true,
      value: {}
    }
  })
}
