<!--
 * @Author: lg9527 13691080728@126.com
 * @Date: 2023-09-17 09:51:48
 * @LastEditors: lg9527 13691080728@126.com
 * @LastEditTime: 2024-07-07 21:30:39
 * @FilePath: \zky_webVue\src\components\visualized\MoreCondition.vue
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
-->
<template>
  <el-form ref="form" :model="condition" label-width="80px" size="mini">
    <el-form-item label="样品编号" label-width="130px">
      <el-input v-model="props.condition.sampleName" style="width: 200px;"></el-input>
    </el-form-item>
    <el-form-item label="参考文献" label-width="130px">
      <el-input v-model="props.condition.reference" style="width: 200px;"></el-input>
    </el-form-item>
    <el-form-item label="岩性小类" label-width="130px">
      <el-input v-model="props.condition.lithology_subdivision" style="width: 200px;"></el-input>
    </el-form-item>
    <el-form-item label="变质级别" label-width="130px">
      <el-select clearable v-model="formData.metamorphic_grade" style="width: 200px;">
        <el-option :label="item.DicName" :value="item.DicValue" v-for="item in bzjb" :key="item.DicValue"></el-option>

      </el-select>
    </el-form-item>
    <el-form-item label="地球动力学背景" label-width="130px">
      <el-select  clearable v-model="formData.geodynamic_settings" style="width: 200px;">
        <el-option :label="item.DicName" :value="item.DicValue" v-for="item in dqdlx" :key="item.DicValue"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="年龄表达方式" label-width="130px">
      <el-select clearable v-model="props.condition.expression" style="width: 200px;">
        <el-option :label="item.DicName" :value="item.DicValue" v-for="item in ndbdfs" :key="item.DicValue"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="年龄解释" label-width="130px">
      <el-select clearable v-model="props.condition.interpretation" style="width: 200px;">
        <el-option :label="item.DicName" :value="item.DicValue" v-for="item in nljs" :key="item.DicValue"></el-option>
      </el-select>
    </el-form-item>
  </el-form>

</template>

<script setup>
import { defineProps, onMounted, ref } from "vue";
import { getDictDetail } from "@/api/common";

const props = defineProps(['condition'])
const bzjb = ref([])
const dqdlx = ref([])
const ndbdfs = ref([])
const nljs = ref([])
const formData = ref({
  bzjb: null,
  dzqdlx: null,
  ndbdfs: null,
  nljs: null
})
async function getBzjb() {
  return getDictDetail(71);
}

async function getDqdlx() {
  return getDictDetail(68);
}

async function getNdbdfs() {
  return getDictDetail(78);
}
async function getNljs() {
  return getDictDetail(79);
}

onMounted(async () => {
  const bzjbData = await getBzjb()
  bzjb.value = bzjbData.rows;
  const dqdlxData = await getDqdlx()
  dqdlx.value = dqdlxData.rows;

  const ndbdfsData = await getNdbdfs()
  ndbdfs.value = ndbdfsData.rows;

  const nljsData = await getNljs()
  nljs.value = nljsData.rows;
})

</script>

<style scoped>

</style>
