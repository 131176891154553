export default [
    {
        "id": 0,
        "ename": "Geologic Time",
        "name":"地质年代",
        "textColor": "white",
        "color": "#000",
        "end": 0,
        "start": 4567
    },
    {
        "id": 782,
        "ename": "Hadean",
        "name":"冥古宙",
        "level": 1,
        "parentId": 0,
        "color": "#B41E8D",
        "end": 4000,
        "start": 4567
    },
    {
        "id": 753,
        "ename": "Archean",
        "name":"太古宙",
        "level": 1,
        "parentId": 0,
        "color": "#F0047F",
        "end": 2500,
        "start": 4000
    },
    {
        "id": 752,
        "ename": "Proterozoic",
        "name":"元古宙",
        "level": 1,
        "parentId": 0,
        "color": "#F73563",
        "end": 538.8,
        "start": 2500
    },
    {
        "id": 751,
        "ename": "Phanerozoic",
        "name":"显生宙",
        "level": 1,
        "parentId": 0,
        "color": "#9AD9DD",
        "end": 0,
        "start": 541
    },
    {
        "id": 760,
        "ename": "Eoarchean",
        "name":"始太古代",
        "level": 2,
        "leaf": true,
        "parentId": 753,
        "color": "#DA037F",
        "end": 3600,
        "start": 4000
    },
    {
        "id": 759,
        "ename": "Paleoarchean",
        "name":"古太古代",
        "level": 2,
        "leaf": true,
        "parentId": 753,
        "color": "#F444A9",
        "end": 3200,
        "start": 3600
    },
    {
        "id": 758,
        "ename": "Mesoarchean",
        "name":"中太古代",
        "level": 2,
        "leaf": true,
        "parentId": 753,
        "color": "#F768A9",
        "end": 2800,
        "start": 3200
    },
    {
        "id": 757,
        "ename": "Neoarchean",
        "name":"新太古代",
        "level": 2,
        "leaf": true,
        "parentId": 753,
        "color": "#F99BC1",
        "end": 2500,
        "start": 2800
    },
    {
        "id": 756,
        "ename": "Paleoproterozoic",
        "name":"古元古代",
        "level": 2,
        "parentId": 752,
        "color": "#F74370",
        "end": 1600,
        "start": 2500
    },
    {
        "id": 755,
        "ename": "Mesoproterozoic",
        "name":"中元古代",
        "level": 2,
        "parentId": 752,
        "color": "#FDB462",
        "end": 1000,
        "start": 1600
    },
    {
        "id": 754,
        "ename": "Neoproterozoic",
        "name":"新元古代",
        "level": 2,
        "parentId": 752,
        "color": "#FEB342",
        "end": 538.8,
        "start": 1000
    },
    {
        "id": 3,
        "ename": "Paleozoic",
        "name":"古生代",
        "abbr": "Pz",
        "level": 2,
        "parentId": 751,
        "color": "#99C08D",
        "end": 251.902,
        "start": 538.8
    },
    {
        "id": 2,
        "ename": "Mesozoic",
        "name":"中生代",
        "abbr": "Mz",
        "level": 2,
        "parentId": 751,
        "color": "#67C5CA",
        "end": 66,
        "start": 251.902
    },
    {
        "id": 1,
        "ename": "Cenozoic",
        "name":"新生代",
        "abbr": "Cz",
        "level": 2,
        "parentId": 751,
        "color": "#F2F91D",
        "end": 0,
        "start": 66
    },
    {
        "id": 770,
        "ename": "Siderian",
        "name":"成铁纪",
        "level": 3,
        "leaf": true,
        "parentId": 756,
        "color": "#F74F7C",
        "end": 2300,
        "start": 2500
    },
    {
        "id": 769,
        "ename": "Rhyacian",
        "name":"层侵纪",
        "level": 3,
        "leaf": true,
        "parentId": 756,
        "color": "#F75B89",
        "end": 2050,
        "start": 2300
    },
    {
        "id": 768,
        "ename": "Orosirian",
        "name":"造山纪",
        "level": 3,
        "leaf": true,
        "parentId": 756,
        "color": "#F76898",
        "end": 1800,
        "start": 2050
    },
    {
        "id": 767,
        "ename": "Statherian",
        "name":"固结纪",
        "level": 3,
        "leaf": true,
        "parentId": 756,
        "color": "#F875A7",
        "end": 1600,
        "start": 1800
    },
    {
        "id": 766,
        "ename": "Calymmian",
        "name":"盖层纪",
        "level": 3,
        "leaf": true,
        "parentId": 755,
        "color": "#FDC07A",
        "end": 1400,
        "start": 1600
    },
    {
        "id": 765,
        "ename": "Ectasian",
        "name":"延展纪",
        "level": 3,
        "leaf": true,
        "parentId": 755,
        "color": "#F3CC8A",
        "end": 1200,
        "start": 1400
    },
    {
        "id": 764,
        "ename": "Stenian",
        "name":"狭带纪",
        "level": 3,
        "leaf": true,
        "parentId": 755,
        "color": "#FED99A",
        "end": 1000,
        "start": 1200
    },
    {
        "id": 763,
        "ename": "Tonian",
        "name":"拉伸纪",
        "level": 3,
        "leaf": true,
        "parentId": 754,
        "color": "#FEBF4E",
        "end": 720,
        "start": 1000
    },
    {
        "id": 762,
        "ename": "Cryogenian",
        "name":"成冰纪",
        "level": 3,
        "leaf": true,
        "parentId": 754,
        "color": "#FECC5C",
        "end": 635,
        "start": 720
    },
    {
        "id": 761,
        "ename": "Ediacaran",
        "name":"埃迪卡拉纪",
        "level": 3,
        "leaf": true,
        "parentId": 754,
        "color": "#FED96A",
        "end": 538.8,
        "start": 635
    },
    {
        "id": 22,
        "ename": "Cambrian",
        "name":"寒武纪",
        "abbr": "Cm",
        "level": 3,
        "parentId": 3,
        "textColor": "white",
        "color": "#7FA056",
        "end": 485.4,
        "start": 538.8
    },
    {
        "id": 21,
        "ename": "Ordovician",
        "name":"奥陶纪",
        "abbr": "O",
        "level": 3,
        "parentId": 3,
        "textColor": "white",
        "color": "#009270",
        "end": 443.8,
        "start": 485.4
    },
    {
        "id": 20,
        "ename": "Silurian",
        "name":"志留纪",
        "abbr": "S",
        "level": 3,
        "parentId": 3,
        "color": "#B3E1B6",
        "end": 419.2,
        "start": 443.8
    },
    {
        "id": 19,
        "ename": "Devonian",
        "name":"泥盆纪",
        "abbr": "D",
        "level": 3,
        "parentId": 3,
        "color": "#CB8C37",
        "end": 358.9,
        "start": 419.2
    },
    {
        "id": 18,
        "ename": "Carboniferous",
        "name":"石炭纪",
        "abbr": "C",
        "level": 3,
        "parentId": 3,
        "textColor": "white",
        "color": "#67A599",
        "end": 298.9,
        "start": 358.9
    },
    {
        "id": 17,
        "ename": "Permian",
        "name":"二叠纪",
        "abbr": "P",
        "level": 3,
        "parentId": 3,
        "color": "#F04028",
        "end": 251.902,
        "start": 298.9
    },
    {
        "id": 16,
        "ename": "Triassic",
        "name":"三叠纪",
        "abbr": "Tr",
        "level": 3,
        "parentId": 2,
        "textColor": "white",
        "color": "#812B92",
        "end": 201.4,
        "start": 251.902
    },
    {
        "id": 15,
        "ename": "Jurassic",
        "name":"侏罗纪",
        "abbr": "J",
        "level": 3,
        "parentId": 2,
        "textColor": "white",
        "color": "#34B2C9",
        "end": 145,
        "start": 201.4
    },
    {
        "id": 14,
        "ename": "Cretaceous",
        "name":"白垩纪",
        "abbr": "K",
        "level": 3,
        "parentId": 2,
        "color": "#7FC64E",
        "end": 66,
        "start": 145
    },
    {
        "id": 26,
        "ename": "Paleogene",
        "name":"古近纪",
        "abbr": "Pg",
        "level": 3,
        "parentId": 1,
        "color": "#FD9A52",
        "end": 23.03,
        "start": 66
    },
    {
        "id": 25,
        "ename": "Neogene",
        "name":"新近纪",
        "abbr": "Ng",
        "level": 3,
        "parentId": 1,
        "color": "#FFE619",
        "end": 2.58,
        "start": 23.03
    },
    {
        "id": 12,
        "ename": "Quaternary",
        "name":"第四纪",
        "level": 3,
        "parentId": 1,
        "color": "#F9F97F",
        "end": 0,
        "start": 2.58
    },
    {
        "id": 1111,
        "ename": "Terreneuvian",
        "name":"纽芬兰世",
        "level": 4,
        "parentId": 22,
        "color": "#8CB06C",
        "end": 521,
        "start": 538.8
    },
    {
        "id": 1110,
        "ename": "Series 2",
        "name":"第二世",
        "level": 4,
        "parentId": 22,
        "color": "#99C078",
        "end": 509,
        "start": 521
    },
    {
        "id": 1109,
        "ename": "Miaolingian",
        "name":"苗岭世",
        "level": 4,
        "parentId": 22,
        "color": "#A6CF86",
        "end": 497,
        "start": 509
    },
    {
        "id": 780,
        "ename": "Furongian",
        "name":"芙蓉世",
        "level": 4,
        "parentId": 22,
        "color": "#B3E095",
        "end": 485.4,
        "start": 497
    },
    {
        "id": 31,
        "ename": "Lower Ordovician",
        "name":"下奥陶世",
        "level": 4,
        "parentId": 21,
        "textColor": "white",
        "color": "#1A9D6F",
        "end": 470,
        "start": 485.4
    },
    {
        "id": 30,
        "ename": "Middle Ordovician",
        "name":"中奥陶世",
        "level": 4,
        "parentId": 21,
        "textColor": "white",
        "color": "#4DB47E",
        "end": 458.4,
        "start": 470
    },
    {
        "id": 29,
        "ename": "Upper Ordovician",
        "name":"上奥陶世",
        "level": 4,
        "parentId": 21,
        "color": "#7FCA93",
        "end": 443.8,
        "start": 458.4
    },
    {
        "id": 62,
        "ename": "Llandovery",
        "name":"兰多维列世",
        "level": 4,
        "parentId": 20,
        "color": "#99D7B3",
        "end": 433.4,
        "start": 443.8
    },
    {
        "id": 61,
        "ename": "Wenlock",
        "name":"温洛克世",
        "level": 4,
        "parentId": 20,
        "color": "#B3E1C2",
        "end": 427.4,
        "start": 433.4
    },
    {
        "id": 60,
        "ename": "Ludlow",
        "name":"罗德洛世",
        "level": 4,
        "parentId": 20,
        "color": "#BFE6CF",
        "end": 423,
        "start": 427.4
    },
    {
        "id": 59,
        "ename": "Pridoli",
        "name":"普里道利世",
        "level": 4,
        "parentId": 20,
        "color": "#E6F5E1",
        "end": 419.2,
        "start": 423
    },
    {
        "id": 58,
        "ename": "Lower Devonian",
        "name":"下泥盆世",
        "level": 4,
        "parentId": 19,
        "color": "#E5AC4D",
        "end": 393.3,
        "start": 419.2
    },
    {
        "id": 57,
        "ename": "Middle Devonian",
        "name":"中泥盆世",
        "level": 4,
        "parentId": 19,
        "color": "#F1C868",
        "end": 382.7,
        "start": 393.3
    },
    {
        "id": 56,
        "ename": "Upper Devonian",
        "name":"上泥盆世",
        "level": 4,
        "parentId": 19,
        "color": "#F1E19D",
        "end": 358.9,
        "start": 382.7
    },
    {
        "id": 28,
        "ename": "Mississippian",
        "name":"密西西比亚纪",
        "level": 4,
        "parentId": 18,
        "textColor": "white",
        "color": "#678F66",
        "end": 323.2,
        "start": 358.9
    },
    {
        "id": 27,
        "ename": "Pennsylvanian",
        "name":"宾夕法尼亚亚纪",
        "level": 4,
        "parentId": 18,
        "color": "#99C2B5",
        "end": 298.9,
        "start": 323.2
    },
    {
        "id": 773,
        "ename": "Cisuralian",
        "name":"乌拉尔世",
        "level": 4,
        "parentId": 17,
        "color": "#EF5845",
        "end": 273.01,
        "start": 298.9
    },
    {
        "id": 772,
        "ename": "Guadalupian",
        "name":"瓜德鲁普世",
        "level": 4,
        "parentId": 17,
        "color": "#FB745C",
        "end": 259.51,
        "start": 273.01
    },
    {
        "id": 771,
        "ename": "Lopingian",
        "name":"乐平世",
        "level": 4,
        "parentId": 17,
        "color": "#FBA794",
        "end": 251.902,
        "start": 259.51
    },
    {
        "id": 46,
        "ename": "Lower Triassic",
        "name":"下三叠世",
        "level": 4,
        "parentId": 16,
        "textColor": "white",
        "color": "#983999",
        "end": 247.2,
        "start": 251.902
    },
    {
        "id": 45,
        "ename": "Middle Triassic",
        "name":"中三叠世",
        "level": 4,
        "parentId": 16,
        "textColor": "white",
        "color": "#B168B1",
        "end": 237,
        "start": 247.2
    },
    {
        "id": 44,
        "ename": "Upper Triassic",
        "name":"上三叠世",
        "level": 4,
        "parentId": 16,
        "textColor": "white",
        "color": "#BD8CC3",
        "end": 201.4,
        "start": 237
    },
    {
        "id": 43,
        "ename": "Lower Jurassic",
        "name":"下侏罗世",
        "level": 4,
        "parentId": 15,
        "textColor": "white",
        "color": "#42AED0",
        "end": 174.7,
        "start": 201.4
    },
    {
        "id": 42,
        "ename": "Middle Jurassic",
        "name":"中侏罗世",
        "level": 4,
        "parentId": 15,
        "color": "#80CFD8",
        "end": 161.5,
        "start": 174.7
    },
    {
        "id": 41,
        "ename": "Upper Jurassic",
        "name":"上侏罗世",
        "level": 4,
        "parentId": 15,
        "color": "#B3E3EE",
        "end": 145,
        "start": 161.5
    },
    {
        "id": 40,
        "ename": "Lower Cretaceous",
        "name":"下白垩世",
        "level": 4,
        "parentId": 14,
        "color": "#8CCD57",
        "end": 100.5,
        "start": 145
    },
    {
        "id": 39,
        "ename": "Upper Cretaceous",
        "name":"上白垩世",
        "level": 4,
        "parentId": 14,
        "color": "#A6D84A",
        "end": 66,
        "start": 100.5
    },
    {
        "id": 38,
        "ename": "Paleocene",
        "name":"古新世",
        "level": 4,
        "parentId": 26,
        "color": "#FDA75F",
        "end": 56,
        "start": 66
    },
    {
        "id": 37,
        "ename": "Eocene",
        "name":"始新世",
        "level": 4,
        "parentId": 26,
        "color": "#FDB46C",
        "end": 33.9,
        "start": 56
    },
    {
        "id": 36,
        "ename": "Oligocene",
        "name":"渐新世",
        "level": 4,
        "parentId": 26,
        "color": "#FDC07A",
        "end": 23.03,
        "start": 33.9
    },
    {
        "id": 35,
        "ename": "Miocene",
        "name":"中新世",
        "level": 4,
        "parentId": 25,
        "color": "#FFFF00",
        "end": 5.333,
        "start": 23.03
    },
    {
        "id": 34,
        "ename": "Pliocene",
        "name":"上新世",
        "level": 4,
        "parentId": 25,
        "color": "#FFFF99",
        "end": 2.58,
        "start": 5.333
    },
    {
        "id": 33,
        "ename": "Pleistocene",
        "name":"更新世",
        "level": 4,
        "parentId": 12,
        "color": "#FFF2AE",
        "end": 0.0117,
        "start": 2.58
    },
    {
        "id": 32,
        "ename": "Holocene",
        "name":"全新世",
        "level": 4,
        "parentId": 12,
        "color": "#FEF2E0",
        "end": 0,
        "start": 0.0117
    },
    {
        "id": 1121,
        "ename": "Fortunian",
        "name":"幸运期",
        "level": 5,
        "leaf": true,
        "parentId": 1111,
        "color": "#99B575",
        "end": 529,
        "start": 538.8
    },
    {
        "id": 1120,
        "ename": "Stage 2",
        "name":"第二期",
        "level": 5,
        "leaf": true,
        "parentId": 1111,
        "color": "#A6BA80",
        "end": 521,
        "start": 529
    },
    {
        "id": 1119,
        "ename": "Stage 3",
        "name":"第三期",
        "level": 5,
        "leaf": true,
        "parentId": 1110,
        "color": "#A6C583",
        "end": 514,
        "start": 521
    },
    {
        "id": 1118,
        "ename": "Stage 4",
        "name":"第四期",
        "level": 5,
        "leaf": true,
        "parentId": 1110,
        "color": "#B3CA8E",
        "end": 509,
        "start": 514
    },
    {
        "id": 1117,
        "ename": "Wuliuan",
        "name":"乌溜期",
        "level": 5,
        "leaf": true,
        "parentId": 1109,
        "color": "#B3D492",
        "end": 504.5,
        "start": 509
    },
    {
        "id": 1116,
        "ename": "Drumian",
        "name":"鼓山期",
        "level": 5,
        "leaf": true,
        "parentId": 1109,
        "color": "#BFD99D",
        "end": 500.5,
        "start": 504.5
    },
    {
        "id": 1087,
        "ename": "Guzhangian",
        "name":"古丈期",
        "level": 5,
        "leaf": true,
        "parentId": 1109,
        "color": "#CCDFAA",
        "end": 497,
        "start": 500.5
    },
    {
        "id": 1114,
        "ename": "Paibian",
        "name":"排碧期",
        "level": 5,
        "leaf": true,
        "parentId": 780,
        "color": "#CCEBAE",
        "end": 494,
        "start": 497
    },
    {
        "id": 1113,
        "ename": "Jiangshanian",
        "name":"江山期",
        "level": 5,
        "leaf": true,
        "parentId": 780,
        "color": "#D9F0BB",
        "end": 489.5,
        "start": 494
    },
    {
        "id": 1112,
        "ename": "Stage 10",
        "name":"第十期",
        "level": 5,
        "leaf": true,
        "parentId": 780,
        "color": "#E6F5C9",
        "end": 485.4,
        "start": 489.5
    },
    {
        "id": 559,
        "ename": "Tremadocian",
        "name":"特马豆克期",
        "level": 5,
        "leaf": true,
        "parentId": 31,
        "textColor": "white",
        "color": "#33A97E",
        "end": 477.7,
        "start": 485.4
    },
    {
        "id": 1010,
        "ename": "Floian",
        "name":"弗洛期",
        "level": 5,
        "leaf": true,
        "parentId": 31,
        "textColor": "white",
        "color": "#41B087",
        "end": 470,
        "start": 477.7
    },
    {
        "id": 1079,
        "ename": "Dapingian",
        "name":"大坪期",
        "level": 5,
        "leaf": true,
        "parentId": 30,
        "textColor": "white",
        "color": "#66C092",
        "end": 467.3,
        "start": 470
    },
    {
        "id": 556,
        "ename": "Darriwilian",
        "name":"达瑞威尔期",
        "level": 5,
        "leaf": true,
        "parentId": 30,
        "textColor": "white",
        "color": "#74C69C",
        "end": 458.4,
        "start": 467.3
    },
    {
        "id": 1009,
        "ename": "Sandbian",
        "name":"桑比期",
        "level": 5,
        "leaf": true,
        "parentId": 29,
        "color": "#8CD094",
        "end": 453,
        "start": 458.4
    },
    {
        "id": 1008,
        "ename": "Katian",
        "name":"凯迪期",
        "level": 5,
        "leaf": true,
        "parentId": 29,
        "color": "#99D69F",
        "end": 445.2,
        "start": 453
    },
    {
        "id": 192,
        "ename": "Hirnantian",
        "name":"赫南特期",
        "level": 5,
        "leaf": true,
        "parentId": 29,
        "color": "#A6DBAB",
        "end": 443.8,
        "start": 445.2
    },
    {
        "id": 191,
        "ename": "Rhuddanian",
        "name":"鲁丹期",
        "level": 5,
        "leaf": true,
        "parentId": 62,
        "color": "#A6DCB5",
        "end": 440.8,
        "start": 443.8
    },
    {
        "id": 190,
        "ename": "Aeronian",
        "name":"埃隆期",
        "level": 5,
        "leaf": true,
        "parentId": 62,
        "color": "#B3E1C2",
        "end": 438.5,
        "start": 440.8
    },
    {
        "id": 189,
        "ename": "Telychian",
        "name":"特列奇期",
        "level": 5,
        "leaf": true,
        "parentId": 62,
        "color": "#BFE6D1",
        "end": 433.4,
        "start": 438.5
    },
    {
        "id": 188,
        "ename": "Sheinwoodian",
        "name":"申伍德期",
        "level": 5,
        "leaf": true,
        "parentId": 61,
        "color": "#BFE6C3",
        "end": 430.5,
        "start": 433.4
    },
    {
        "id": 785,
        "ename": "Homerian",
        "name":"侯墨期",
        "level": 5,
        "leaf": true,
        "parentId": 61,
        "color": "#CCEBD1",
        "end": 427.4,
        "start": 430.5
    },
    {
        "id": 185,
        "ename": "Gorstian",
        "name":"高斯特期",
        "level": 5,
        "leaf": true,
        "parentId": 60,
        "color": "#CCECDD",
        "end": 425.6,
        "start": 427.4
    },
    {
        "id": 184,
        "ename": "Ludfordian",
        "name":"卢德福特期",
        "level": 5,
        "leaf": true,
        "parentId": 60,
        "color": "#D9F0DF",
        "end": 423,
        "start": 425.6
    },
    {
        "id": 3001,
        "ename": "Pridoli",
        "name":"普里道利世",
        "level": 5,
        "leaf": true,
        "parentId": 59,
        "color": "#E6F5E1",
        "end": 419.2,
        "start": 423
    },
    {
        "id": 183,
        "ename": "Lochkovian",
        "name":"洛赫考夫期",
        "level": 5,
        "leaf": true,
        "parentId": 58,
        "color": "#E5B75A",
        "end": 410.8,
        "start": 419.2
    },
    {
        "id": 182,
        "ename": "Pragian",
        "name":"布拉格期",
        "level": 5,
        "leaf": true,
        "parentId": 58,
        "color": "#E5C468",
        "end": 407.6,
        "start": 410.8
    },
    {
        "id": 181,
        "ename": "Emsian",
        "name":"埃姆斯期",
        "level": 5,
        "leaf": true,
        "parentId": 58,
        "color": "#E5D075",
        "end": 393.3,
        "start": 407.6
    },
    {
        "id": 180,
        "ename": "Eifelian",
        "name":"艾菲尔期",
        "level": 5,
        "leaf": true,
        "parentId": 57,
        "color": "#F1D576",
        "end": 387.7,
        "start": 393.3
    },
    {
        "id": 179,
        "ename": "Givetian",
        "name":"吉维特期",
        "level": 5,
        "leaf": true,
        "parentId": 57,
        "color": "#F1E185",
        "end": 382.7,
        "start": 387.7
    },
    {
        "id": 178,
        "ename": "Frasnian",
        "name":"弗拉期",
        "level": 5,
        "leaf": true,
        "parentId": 56,
        "color": "#F2EDAD",
        "end": 372.2,
        "start": 382.7
    },
    {
        "id": 177,
        "ename": "Famennian",
        "name":"法门期",
        "level": 5,
        "leaf": true,
        "parentId": 56,
        "color": "#F2EDC5",
        "end": 358.9,
        "start": 372.2
    },
    {
        "id": 55,
        "ename": "Tournaisian",
        "name":"杜内期",
        "level": 5,
        "leaf": true,
        "parentId": 28,
        "color": "#8CB06C",
        "end": 346.7,
        "start": 358.9
    },
    {
        "id": 54,
        "ename": "Visean",
        "name":"维宪期",
        "level": 5,
        "leaf": true,
        "parentId": 28,
        "color": "#A6B96C",
        "end": 330.9,
        "start": 346.7
    },
    {
        "id": 53,
        "ename": "Serpukhovian",
        "name":"谢尔普霍夫期",
        "level": 5,
        "leaf": true,
        "parentId": 28,
        "color": "#BFC26B",
        "end": 323.2,
        "start": 330.9
    },
    {
        "id": 52,
        "ename": "Bashkirian",
        "name":"巴什基尔期",
        "level": 5,
        "leaf": true,
        "parentId": 27,
        "color": "#99C2B6",
        "end": 315.2,
        "start": 323.2
    },
    {
        "id": 51,
        "ename": "Moscovian",
        "name":"莫斯科期",
        "level": 5,
        "leaf": true,
        "parentId": 27,
        "color": "#B3CBB9",
        "end": 307,
        "start": 315.2
    },
    {
        "id": 50,
        "ename": "Kasimovian",
        "name":"卡西莫夫期",
        "level": 5,
        "leaf": true,
        "parentId": 27,
        "color": "#BFD0C5",
        "end": 303.7,
        "start": 307
    },
    {
        "id": 49,
        "ename": "Gzhelian",
        "name":"格舍尔期",
        "level": 5,
        "leaf": true,
        "parentId": 27,
        "color": "#CCD4C7",
        "end": 298.9,
        "start": 303.7
    },
    {
        "id": 151,
        "ename": "Asselian",
        "name":"阿瑟尔期",
        "level": 5,
        "leaf": true,
        "parentId": 773,
        "color": "#E36350",
        "end": 293.52,
        "start": 298.9
    },
    {
        "id": 150,
        "ename": "Sakmarian",
        "name":"萨克马尔期",
        "level": 5,
        "leaf": true,
        "parentId": 773,
        "color": "#E36F5C",
        "end": 290.1,
        "start": 293.52
    },
    {
        "id": 149,
        "ename": "Artinskian",
        "name":"亚丁斯克期",
        "level": 5,
        "leaf": true,
        "parentId": 773,
        "color": "#E37B68",
        "end": 283.5,
        "start": 290.1
    },
    {
        "id": 148,
        "ename": "Kungurian",
        "name":"空谷期",
        "level": 5,
        "leaf": true,
        "parentId": 773,
        "color": "#E38776",
        "end": 273.01,
        "start": 283.5
    },
    {
        "id": 717,
        "ename": "Roadian",
        "name":"罗德期",
        "level": 5,
        "leaf": true,
        "parentId": 772,
        "color": "#FB8069",
        "end": 266.9,
        "start": 273.01
    },
    {
        "id": 146,
        "ename": "Wordian",
        "name":"沃德期",
        "level": 5,
        "leaf": true,
        "parentId": 772,
        "color": "#FB8D76",
        "end": 264.28,
        "start": 266.9
    },
    {
        "id": 145,
        "ename": "Capitanian",
        "name":"卡匹敦期",
        "level": 5,
        "leaf": true,
        "parentId": 772,
        "color": "#FB9A85",
        "end": 259.51,
        "start": 264.28
    },
    {
        "id": 716,
        "ename": "Wuchiapingian",
        "name":"吴家坪期",
        "level": 5,
        "leaf": true,
        "parentId": 771,
        "color": "#FCB4A2",
        "end": 254.14,
        "start": 259.51
    },
    {
        "id": 715,
        "ename": "Changhsingian",
        "name":"长兴期",
        "level": 5,
        "leaf": true,
        "parentId": 771,
        "color": "#FCC0B2",
        "end": 251.902,
        "start": 254.14
    },
    {
        "id": 653,
        "ename": "Induan",
        "name":"印度期",
        "level": 5,
        "leaf": true,
        "parentId": 46,
        "textColor": "white",
        "color": "#A4469F",
        "end": 251.2,
        "start": 251.902
    },
    {
        "id": 652,
        "ename": "Olenekian",
        "name":"奥伦尼克期",
        "level": 5,
        "leaf": true,
        "parentId": 46,
        "textColor": "white",
        "color": "#B051A5",
        "end": 247.2,
        "start": 251.2
    },
    {
        "id": 139,
        "ename": "Anisian",
        "name":"安尼期",
        "level": 5,
        "leaf": true,
        "parentId": 45,
        "textColor": "white",
        "color": "#BC75B7",
        "end": 242,
        "start": 247.2
    },
    {
        "id": 138,
        "ename": "Ladinian",
        "name":"拉丁期",
        "level": 5,
        "leaf": true,
        "parentId": 45,
        "textColor": "white",
        "color": "#C983BF",
        "end": 237,
        "start": 242
    },
    {
        "id": 137,
        "ename": "Carnian",
        "name":"卡尼期",
        "level": 5,
        "leaf": true,
        "parentId": 44,
        "color": "#C99BCB",
        "end": 227,
        "start": 237
    },
    {
        "id": 136,
        "ename": "Norian",
        "name":"诺利期",
        "level": 5,
        "leaf": true,
        "parentId": 44,
        "color": "#D6AAD3",
        "end": 208.5,
        "start": 227
    },
    {
        "id": 135,
        "ename": "Rhaetian",
        "name":"瑞替期",
        "level": 5,
        "leaf": true,
        "parentId": 44,
        "textColor": "white",
        "color": "#E3B9DB",
        "end": 201.4,
        "start": 208.5
    },
    {
        "id": 134,
        "ename": "Hettangian",
        "name":"赫塘期",
        "level": 5,
        "leaf": true,
        "parentId": 43,
        "textColor": "white",
        "color": "#4EB3D3",
        "end": 199.5,
        "start": 201.4
    },
    {
        "id": 133,
        "ename": "Sinemurian",
        "name":"辛涅缪尔期",
        "level": 5,
        "leaf": true,
        "parentId": 43,
        "textColor": "white",
        "color": "#67BCD8",
        "end": 192.9,
        "start": 199.5
    },
    {
        "id": 132,
        "ename": "Pliensbachian",
        "name":"普林斯巴期",
        "level": 5,
        "leaf": true,
        "parentId": 43,
        "textColor": "white",
        "color": "#80C5DD",
        "end": 184.2,
        "start": 192.9
    },
    {
        "id": 131,
        "ename": "Toarcian",
        "name":"托阿尔期",
        "level": 5,
        "leaf": true,
        "parentId": 43,
        "color": "#99CEE3",
        "end": 174.7,
        "start": 184.2
    },
    {
        "id": 130,
        "ename": "Aalenian",
        "name":"阿林期",
        "level": 5,
        "leaf": true,
        "parentId": 42,
        "color": "#9AD9DD",
        "end": 170.9,
        "start": 174.7
    },
    {
        "id": 129,
        "ename": "Bajocian",
        "name":"巴柔期",
        "level": 5,
        "leaf": true,
        "parentId": 42,
        "color": "#A6DDE0",
        "end": 168.2,
        "start": 170.9
    },
    {
        "id": 128,
        "ename": "Bathonian",
        "name":"巴通期",
        "level": 5,
        "leaf": true,
        "parentId": 42,
        "color": "#B3E2E3",
        "end": 165.3,
        "start": 168.2
    },
    {
        "id": 127,
        "ename": "Callovian",
        "name":"卡洛夫期",
        "level": 5,
        "leaf": true,
        "parentId": 42,
        "color": "#BFE7E5",
        "end": 161.5,
        "start": 165.3
    },
    {
        "id": 126,
        "ename": "Oxfordian",
        "name":"牛津期",
        "level": 5,
        "leaf": true,
        "parentId": 41,
        "color": "#BFE7F1",
        "end": 154.8,
        "start": 161.5
    },
    {
        "id": 125,
        "ename": "Kimmeridgian",
        "name":"钦莫利期",
        "level": 5,
        "leaf": true,
        "parentId": 41,
        "color": "#CCECF4",
        "end": 149.2,
        "start": 154.8
    },
    {
        "id": 124,
        "ename": "Tithonian",
        "name":"提塘期",
        "level": 5,
        "leaf": true,
        "parentId": 41,
        "color": "#D9F1F7",
        "end": 145,
        "start": 149.2
    },
    {
        "id": 123,
        "ename": "Berriasian",
        "name":"贝里阿斯期",
        "level": 5,
        "leaf": true,
        "parentId": 40,
        "color": "#8CCD60",
        "end": 139.8,
        "start": 145
    },
    {
        "id": 122,
        "ename": "Valanginian",
        "name":"瓦兰今期",
        "level": 5,
        "leaf": true,
        "parentId": 40,
        "color": "#99D36A",
        "end": 132.6,
        "start": 139.8
    },
    {
        "id": 121,
        "ename": "Hauterivian",
        "name":"欧特里夫期",
        "level": 5,
        "leaf": true,
        "parentId": 40,
        "color": "#A6D975",
        "end": 125.77,
        "start": 132.6
    },
    {
        "id": 120,
        "ename": "Barremian",
        "name":"巴雷姆期",
        "level": 5,
        "leaf": true,
        "parentId": 40,
        "color": "#B3DF7F",
        "end": 121.4,
        "start": 125.77
    },
    {
        "id": 119,
        "ename": "Aptian",
        "name":"阿普特期",
        "level": 5,
        "leaf": true,
        "parentId": 40,
        "color": "#BFE48A",
        "end": 113,
        "start": 121.4
    },
    {
        "id": 118,
        "ename": "Albian",
        "name":"阿尔布期",
        "level": 5,
        "leaf": true,
        "parentId": 40,
        "color": "#CCEA97",
        "end": 100.5,
        "start": 113
    },
    {
        "id": 117,
        "ename": "Cenomanian",
        "name":"塞诺曼期",
        "level": 5,
        "leaf": true,
        "parentId": 39,
        "color": "#B3DE53",
        "end": 93.9,
        "start": 100.5
    },
    {
        "id": 116,
        "ename": "Turonian",
        "name":"土伦期",
        "level": 5,
        "leaf": true,
        "parentId": 39,
        "color": "#BFE35D",
        "end": 89.8,
        "start": 93.9
    },
    {
        "id": 115,
        "ename": "Coniacian",
        "name":"康尼亚克期",
        "level": 5,
        "leaf": true,
        "parentId": 39,
        "color": "#CCE968",
        "end": 86.3,
        "start": 89.8
    },
    {
        "id": 114,
        "ename": "Santonian",
        "name":"圣通期",
        "level": 5,
        "leaf": true,
        "parentId": 39,
        "color": "#D9EF74",
        "end": 83.6,
        "start": 86.3
    },
    {
        "id": 113,
        "ename": "Campanian",
        "name":"坎潘期",
        "level": 5,
        "leaf": true,
        "parentId": 39,
        "color": "#E6F47F",
        "end": 72.1,
        "start": 83.6
    },
    {
        "id": 112,
        "ename": "Maastrichtian",
        "name":"马斯特里赫特期",
        "level": 5,
        "leaf": true,
        "parentId": 39,
        "color": "#F2FA8C",
        "end": 66,
        "start": 72.1
    },
    {
        "id": 111,
        "ename": "Danian",
        "name":"丹麦期",
        "level": 5,
        "leaf": true,
        "parentId": 38,
        "color": "#FDB462",
        "end": 61.6,
        "start": 66
    },
    {
        "id": 743,
        "ename": "Selandian",
        "name":"塞兰特期",
        "level": 5,
        "leaf": true,
        "parentId": 38,
        "color": "#FEBF65",
        "end": 59.2,
        "start": 61.6
    },
    {
        "id": 110,
        "ename": "Thanetian",
        "name":"坦尼特期",
        "level": 5,
        "leaf": true,
        "parentId": 38,
        "color": "#FDBF6F",
        "end": 56,
        "start": 59.2
    },
    {
        "id": 109,
        "ename": "Ypresian",
        "name":"伊普里斯期",
        "level": 5,
        "leaf": true,
        "parentId": 37,
        "color": "#FCA773",
        "end": 47.8,
        "start": 56
    },
    {
        "id": 108,
        "ename": "Lutetian",
        "name":"卢泰特期",
        "level": 5,
        "leaf": true,
        "parentId": 37,
        "color": "#FCB482",
        "end": 41.2,
        "start": 47.8
    },
    {
        "id": 107,
        "ename": "Bartonian",
        "name":"巴顿期",
        "level": 5,
        "leaf": true,
        "parentId": 37,
        "color": "#FDC091",
        "end": 37.71,
        "start": 41.2
    },
    {
        "id": 106,
        "ename": "Priabonian",
        "name":"普利亚本期",
        "level": 5,
        "leaf": true,
        "parentId": 37,
        "color": "#FDCDA1",
        "end": 33.9,
        "start": 37.71
    },
    {
        "id": 105,
        "ename": "Rupelian",
        "name":"吕珀尔期",
        "level": 5,
        "leaf": true,
        "parentId": 36,
        "color": "#FED99A",
        "end": 27.82,
        "start": 33.9
    },
    {
        "id": 104,
        "ename": "Chattian",
        "name":"夏特期",
        "level": 5,
        "leaf": true,
        "parentId": 36,
        "color": "#FEE6AA",
        "end": 23.03,
        "start": 27.82
    },
    {
        "id": 103,
        "ename": "Aquitanian",
        "name":"阿基坦期",
        "level": 5,
        "leaf": true,
        "parentId": 35,
        "color": "#FFFF33",
        "end": 20.44,
        "start": 23.03
    },
    {
        "id": 102,
        "ename": "Burdigalian",
        "name":"波尔多期",
        "level": 5,
        "leaf": true,
        "parentId": 35,
        "color": "#FFFF41",
        "end": 15.98,
        "start": 20.44
    },
    {
        "id": 101,
        "ename": "Langhian",
        "name":"兰盖期",
        "level": 5,
        "leaf": true,
        "parentId": 35,
        "color": "#FFFF4D",
        "end": 13.82,
        "start": 15.98
    },
    {
        "id": 100,
        "ename": "Serravallian",
        "name":"塞拉瓦莱期",
        "level": 5,
        "leaf": true,
        "parentId": 35,
        "color": "#FFFF59",
        "end": 11.63,
        "start": 13.82
    },
    {
        "id": 99,
        "ename": "Tortonian",
        "name":"托尔托纳期",
        "level": 5,
        "leaf": true,
        "parentId": 35,
        "color": "#FFFF66",
        "end": 7.246,
        "start": 11.63
    },
    {
        "id": 98,
        "ename": "Messinian",
        "name":"墨西拿期",
        "level": 5,
        "leaf": true,
        "parentId": 35,
        "color": "#FFFF73",
        "end": 5.333,
        "start": 7.246
    },
    {
        "id": 97,
        "ename": "Zanclean",
        "name":"赞克勒期",
        "level": 5,
        "leaf": true,
        "parentId": 34,
        "color": "#FFFFB3",
        "end": 3.6,
        "start": 5.333
    },
    {
        "id": 96,
        "ename": "Piacenzian",
        "name":"皮亚琴察期",
        "level": 5,
        "leaf": true,
        "parentId": 34,
        "color": "#FFFFBF",
        "end": 2.58,
        "start": 3.6
    },
    {
        "id": 741,
        "ename": "Gelasian",
        "name":"杰拉期",
        "level": 5,
        "leaf": true,
        "parentId": 33,
        "color": "#FFEDB3",
        "end": 1.80,
        "start": 2.58
    },
    {
        "id": 740,
        "ename": "Calabbrian",
        "name":"卡拉布里雅期",
        "level": 5,
        "leaf": true,
        "parentId": 33,
        "color": "#FFF2BA",
        "end": 0.774,
        "start": 1.80
    },
    {
        "id": 923,
        "ename": "Chibanian",
        "name":"千叶期",
        "level": 5,
        "leaf": true,
        "parentId": 33,
        "color": "#FFF2C7",
        "end": 0.129,
        "start": 0.774
    },
    {
        "id": 922,
        "ename": "Upper Pleistocene",
        "name":"上期",
        "level": 5,
        "leaf": true,
        "parentId": 33,
        "color": "#FFF2D3",
        "end": 0.0117,
        "start": 0.129
    },
    {
        "id": 3002,
        "ename": "Greenlandian",
        "name":"格陵兰期",
        "level": 5,
        "leaf": true,
        "parentId": 32,
        "color": "#FEECDB",
        "end": 0.0082,
        "start": 0.0117
    },
    {
        "id": 3003,
        "ename": "Northgrippian",
        "name":"诺斯格瑞比期",
        "level": 5,
        "leaf": true,
        "parentId": 32,
        "color": "#FDECE4",
        "end": 0.0042,
        "start": 0.008
    },
    {
        "id": 3004,
        "ename": "Meghalayan",
        "name":"梅加拉亚期",
        "level": 5,
        "leaf": true,
        "parentId": 32,
        "color": "#FDEDEC",
        "end": 0,
        "start": 0.0042
    }
]