<template>
  <div class="base-map-switch collapsed">
    <div v-for="o in Object.keys(baseMaps)" class="basemap-item" :key="o" @click="switchLayer(o)">
      <div :class="{selected:o===currentLayerName}">{{ o }}</div>
    </div>
  </div>
</template>

<script setup>
import { ref, defineExpose, watch } from "vue";
import L from "leaflet";
const baseMaps = {
  街道: {
    layer: L.tileLayer('http://t0.tianditu.gov.cn/vec_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=vec&STYLE=default&TILEMATRIXSET=c&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=1d587a982d803b2a9f1ad58f2764e29e', {
      maxZoom: 19,
      zoomOffset: 1,
      crs: L.CRS.EPSG4326
    }),
    image: ""
  },
  影像: {
    layer: L.tileLayer('http://t0.tianditu.gov.cn/img_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=img&STYLE=default&TILEMATRIXSET=c&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=1d587a982d803b2a9f1ad58f2764e29e', {
      maxZoom: 19,
      zoomOffset: 1,
      crs: L.CRS.EPSG4326
    }),
    image: ""
  },
  地形: {
    layer: L.tileLayer('http://t0.tianditu.gov.cn/ter_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=ter&STYLE=default&TILEMATRIXSET=c&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=1d587a982d803b2a9f1ad58f2764e29e', {
      maxZoom: 19,
      zoomOffset: 1,
      crs: L.CRS.EPSG4326
    }),
    image: ""
  }
}

const currentLayerName = ref("");
function switchLayer(id) {
  currentLayerName.value = id
}
let myMap = null
let currentLayer = null
function setMap(map) {
  myMap = map
  currentLayerName.value = "街道"
  L.tileLayer('http://t0.tianditu.gov.cn/eia_c/wmts?SERVICE=WMTS&REQUEST=GetTile&VERSION=1.0.0&LAYER=eia&STYLE=default&TILEMATRIXSET=c&FORMAT=tiles&TILEMATRIX={z}&TILEROW={y}&TILECOL={x}&tk=1d587a982d803b2a9f1ad58f2764e29e', {
    maxZoom: 19,
    zoomOffset: 1,
    crs: L.CRS.EPSG4326
  }).addTo(myMap)
}

watch(currentLayerName, (newV) => {
  reCreateBaseLayer(newV)
})

function reCreateBaseLayer(id) {
  // debugger
  if (currentLayer) {
    myMap.removeLayer(currentLayer)
  }
  const myLayer = baseMaps[id].layer
  myLayer.addTo(myMap)
  currentLayer = myLayer
  currentLayer.bringToBack()
}

defineExpose({ setMap })
</script>

<style scoped>
.base-map-switch {
  background: #fff;
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
  padding: 0 10px;
  align-items: center;
  overflow: hidden;
  transition: width 0.3s;
  border-radius: 10px;
}

.collapsed{
  width: 100px;
}

.base-map-switch .basemap-item {
  height: 80px;
  width: 80px;
  border-radius: 5px;
  background: url("/images/dt.png");
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  color: #fff;
  margin: 0 5px;
  flex-shrink: 0;

  font-size: 12px;
}
.base-map-switch .basemap-item:first-child{
  background: url("/images/dt.png");
}
.base-map-switch .basemap-item:nth-child(2){
  background: url("/images/yx.png");
}
.base-map-switch .basemap-item:last-child{
  background: url("/images/dx.png");
}

.base-map-switch .basemap-item div{
  background: rgb(18, 110, 230);
  width: 100%;
  text-align: center;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
}

.basemap-item div.selected{
  background: rgba(99, 240, 103, 0.88);
}

.base-map-switch .basemap-item:hover{
  opacity: 0.8;
  cursor: pointer;
}

.base-map-switch .basemap-item:first-child {

  margin-right: 0;

}

.base-map-switch .basemap-item:last-child {
  margin-left: 0;
}

.base-map-switch:hover{
  width: auto;
}
</style>
