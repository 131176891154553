import L from 'leaflet'
import { getRangePoint, getSampleBasicInfo } from "@/api/sampleAPI";
var CustomLayer = L.Layer.extend({
  initialize: function() {
    this.circles = [];
  },

  addCircle: function(lat, lng, number, size, sampleIds,sample_Names) {
    let sizeReslut = size;
    if (size > 50) {
      sizeReslut = 50
    } else if (size === 1) {
      sizeReslut = 15
    } else if (size > 1 && size < 3) {
      sizeReslut = 40
    } else {
      sizeReslut = 35
    }

    let colorClassName = size === 1 ? "text-label text-label-single" : "text-label";
    if (number > 100) {
      colorClassName += " circle-color-1"
    } else if (number < 100 && number > 50) {
      colorClassName += " circle-color-2"
    } else if (number <= 50 && number >= 20) {
      colorClassName += " circle-color-2"
    }else if (number < 20) {
      colorClassName += " circle-color-4"
    }

    const sampleName = sample_Names[0]
    const sampleid = sampleIds[0]
    var text = L.divIcon({ iconSize: [sizeReslut, sizeReslut], iconAnchor: [sizeReslut / 2, sizeReslut / 2], className: colorClassName, html: number > 1 ? number : `<span class="title">${sampleName}</span><div class="dot"></div>` });
    var numberMarker = L.marker([lat, lng], { icon: text });

    this.circles.push(numberMarker);
    // this._map.addLayer(circle);
    this._map.addLayer(numberMarker);


    numberMarker.on('click', async (e) => {
      if (size > 1) {
        console.log(e)
        this._map.setView(e.latlng, this._map.getZoom() + 1);
      } else {
        const res = await getSampleBasicInfo(sampleid)
        const basicInfo = res.data
        console.log(basicInfo)
        var popupContent = `
    <div class="my-popup">
      <ul>
       <li>样品名称:<span>${basicInfo.sample_Name}</span></li>
        <li>年龄值(Ma):<span>${basicInfo.age}</span></li>
        <li>岩性小类:<span>${basicInfo.lithology_subdivision}</span></li>
        <li>岩石或地层单元:<span>${basicInfo.rock_or_stratigraphic_unit}</span></li>
        <li>测定对象:<span>${basicInfo.object_or_mineral_determined}</span></li>
      </ul>
      <a href="/#/search/result-detail/sample?sampleId=${sampleid}" target="_blank">详细信息</a>
     
    </div>
  `;
        var customPopup = L.popup().setContent(popupContent);
        customPopup.setLatLng(e.latlng).openOn(this._map);
      }
    });
  },

  clearCircles: function() {
    this.circles.forEach(function(circle) {
      this._map.removeLayer(circle);
    }, this);
    this.circles = [];
  },

  fetchData: function(requestParams) {
    const zoom = requestParams.zoom
    const step = 2 * zoom + 10
    return getRangePoint(requestParams.west, requestParams.east, requestParams.south, requestParams.north, step)
    // return new Promise(function(resolve, reject) {
    //   var coordinates = [];
    //   for (var i = 0; i < 100; i++) {
    //     var lat = Math.random() * (requestParams.north - requestParams.south) + requestParams.south;
    //     var lng = Math.random() * (requestParams.east - requestParams.west) + requestParams.west;
    //     coordinates.push({ lat: lat, lng: lng,size:  Math.random()*60});
    //   }
    //
    //   setTimeout(function() {
    //     resolve({ coordinates: coordinates });
    //   }, 200);
    // });
  },

  _update: function() {
    var bounds = this._map.getBounds();
    var ne = bounds.getNorthEast();
    var sw = bounds.getSouthWest();
    var zoom = this._map.getZoom();

    var requestParams = {
      north: ne.lat,
      east: ne.lng,
      south: sw.lat,
      west: sw.lng,
      zoom: zoom
    };

    this.fetchData(requestParams)
      .then(function(response) {
        const data = response.data;

        if (data) {
          this.clearCircles();
          const arr = data;
          arr.forEach(function(item, index) {
            this.addCircle(item.geom.coordinates[1], item.geom.coordinates[0], item.count, item.count, item.sampleIds, item.sample_Names);
          }, this);
        }
      }.bind(this))
      .catch(function(error) {
        console.error('Error fetching data:', error);
      });
  },

  onAdd: function(map) {
    this._map = map;
    this._update();
    map.on('moveend', this._update, this);
  },

  onRemove: function(map) {
    map.off('moveend', this._update, this);
    this.clearCircles();
  }
});

export default CustomLayer
