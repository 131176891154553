<template>
  <el-form ref="form" :model="condition" label-width="80px" size="mini">
    <el-form-item label="地理位置" label-width="130px">
      <el-input v-model="condition.detailed_locality" style="width: 200px;"></el-input>
    </el-form-item>
    <el-form-item label="地体" label-width="130px">
      <el-input v-model="condition.terrane" style="width: 200px;"></el-input>
    </el-form-item>
    <el-form-item label="岩石/地层单元" label-width="130px">
      <el-input v-model="condition.rock_or_stratigraphic_unit" style="width: 200px;"></el-input>
    </el-form-item>
    <el-form-item label="地质时代" label-width="130px">
      <el-select v-model="condition.geological_period" clearable style="width: 200px;">
        <el-option :label="item.DicName" :value="item.DicValue" v-for="item in dzsd" :key="item.DicValue"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="岩性大类" label-width="130px">
      <el-select v-model="condition.lithology" clearable style="width: 200px;">
        <el-option :label="item.DicName" :value="item.DicValue" v-for="item in yxdl" :key="item"></el-option>
      </el-select>
    </el-form-item>
    <el-form-item label="测定对象" label-width="130px">
      <el-input v-model="condition.object_or_mineral_determined" style="width: 200px;"></el-input>
    </el-form-item>
<!--    <el-form-item label="测定内容" label-width="130px">-->
<!--      <el-input v-model="form.name" style="width: 200px;"></el-input>-->
<!--    </el-form-item>-->
    <el-form-item label="年龄值(Ma)" label-width="130px">

      <el-slider
          v-model="sliderValue"
          range
          :min="0"
          :max="4567">
      </el-slider>
    </el-form-item>
  </el-form>

</template>

<script setup>
import { defineProps, onMounted, ref, watch } from "vue";
import { getDictDetail } from "@/api/common";

const props = defineProps(['condition'])
const { ageStart, ageEnd } = props.condition
const sliderValue = ref([ageStart ? ageStart : 0, ageEnd ? ageEnd : 0]);

watch(sliderValue, () => {
  props.condition.ageStart = sliderValue.value[0];
  props.condition.ageEnd = sliderValue.value[1];
})

const dzsd = ref([])
const yxdl = ref([])
// const yzdlOptions = ref([
//   "岩浆岩",
//   "变质岩",
//   "沉积岩"
// ])

const formData = ref({
  dzsd: null,
  yxdl: null
})

async function getDzsd() {
  return getDictDetail(90);
}
async function getYxdl() {
  return getDictDetail(74);
}
onMounted(async () => {
  const dzsdData = await getDzsd()
  dzsd.value = dzsdData.rows;

  const yxdlData = await getYxdl()
  yxdl.value = yxdlData.rows;
})

</script>

<style scoped>
.el-form-item--mini.el-form-item, .el-form-item--small.el-form-item{
  margin-bottom: 3px;
}
</style>
