<template>
  <el-tree
    ref="treeRef"
    :data="treeData"
    show-checkbox
    node-key="key"
    :default-expanded-keys="defaultExpandedKeys"
    @check="onCheck"
    :props="defaultProps">
  </el-tree>
</template>

<script setup>
import { onMounted, ref, watch } from "vue";
import { Message } from 'element-ui';
import { getWMTSTree } from "@/api/wmtsCatalog";
import { convertTreeData } from "@/utils/treeUtils";
import L from "leaflet";

const treeRef = ref(null)
const treeData = ref([])
const defaultProps = {
  disabled: "",
  isLeaf: "",
  children: 'children',
  label: 'label'
}
const defaultExpandedKeys = ref([]) // 默认展开keys
let oldCheckedKeys = [] // 记录上次选中keys

onMounted(async () => {
  const data = await getWMTSTree()
  let treeList = convertTreeData(data.rows, 0)
  console.log('treedata:', treeList)
  defaultExpandedKeys.value = treeList.map(item => item.key)
  console.log('defaultExpandedKeys.value:', defaultExpandedKeys.value)
  treeData.value = treeList
})

function extractContentServiceName99(url) {
  // 通过正则表达式匹配提取内容
  const regex = /\/ogc\/([^/]+)\//;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1]; // 返回匹配到的内容
  } else {
    return null; // 如果未匹配到，返回空值或者你想要的其他值
  }
}

function extractUrlRoot99(url) {
  // 通过正则表达式匹配提取内容
  const regex = /(.+)\/WMTSCapabilities\.xml/;
  const match = url.match(regex);

  if (match && match[1]) {
    return match[1]; // 返回匹配到的内容
  } else {
    return null; // 如果未匹配到，返回空值或者你想要的其他值
  }
}

function onCheck(nodeData, checkInfo) {
  // console.log('onCheck')
  // console.log('nodeData:', nodeData)
  // console.log('checkInfo:', checkInfo)
  
  let isChecked = checkInfo.checkedKeys.includes(nodeData.key) // 是否被选中
  let leafData = getLeafData([nodeData]) // 获取当前节点的叶子节点
  let leafDataNew = leafData.filter(item => !oldCheckedKeys.includes(item.key)) // 本次新选择的叶子节点
  let limit = 5 // 同时叠加显示图层数最大限制数量
  let isLimit = leafDataNew.length > limit // 是否超出限制
  // console.log('leafDataNew:', leafDataNew)
  if (isChecked && isLimit) { // 超限制
    Message({
      message: `同时叠加显示图层数最大为${limit}个！请重新选择`,
      type: 'warning'
    })
    // 取消新选中
    leafDataNew.forEach(item => {
      treeRef.value.setChecked(item.key, false, false)
    })
  } else if (isChecked && !isLimit) { // 未超限制
    addLayer(leafDataNew) // 添加图层
  } else if (!isChecked) { // 取消选择
    removeLayer(leafData) // 删除图层
  }
  oldCheckedKeys = checkInfo.checkedKeys
}
// 递归获取叶子数据集合
function getLeafData(data) {
  let arr = []
  data.forEach(item => {
    if (item.children?.length) {
      arr.push(...getLeafData(item.children))
    } else {
      arr.push(item)
    }
  })
  return arr
}
// 追加图层
function addLayer(data) {
  data.forEach(item => {
    if (item.serviceType === 99) {
        const layerName = extractContentServiceName99(item.wmsurl);
        const urlRoot = extractUrlRoot99(item.wmsurl);
        const layer = L.tileLayer(`${urlRoot}/${layerName}/default/EPSG:4326_qg20_20210401_FCnDDRJd_028mm_GB/{z}/{y}/{x}.png?tk=eyJhbGciOiJIUzI1NiJ9.eyJhdWQiOiI4OTk4Njg4OS0xYTYxLTRhZDAtYmZmZC00OGNlNmJmMzAyZjcifQ.YbLEsuhzeS4cQHXtEBxgsCtplvIvO3wtknvgGnwxJa8`, {
          maxZoom: 18,
          tileSize: 256,
          opacity: 0.75,
          crs: L.CRS.EPSG4326
        });
        layer.addTo(myMap);
        item.layer = layer;
    } else {
      const layerName = item.wmsurl.match(/kvp\/(.*?)\/WMTSServer/)[1];
      const layer = L.tileLayer(`${item.wmsurl}/1.0.0/${layerName}/default/EPSG:4326${layerName}_dpi96_GB/{z}/{y}/{x}.png`, {
        maxZoom: 18,
        tileSize: 256,
        opacity: 0.75,
        crs: L.CRS.EPSG4326
      });
      layer.addTo(myMap);
      item.layer = layer;
    }
  })
}
// 移除图层
function removeLayer(data) {
  data.forEach(item => {
    if (item.layer) {
      item.layer?.remove()
      delete item.layer
    }
  })
}

let myMap = null
function setMap(map) {
  myMap = map
}
defineExpose({ setMap })

</script>

<style scoped>

</style>
