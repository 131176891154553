/**
 * 主量元素筛选条件字段列表
 * @type {[{options: [{label: string, value: string},{label: string, value: string}], label: string},{options: [{label: string, value: string},{label: string, value: string},{label: string, value: string}], label: string}]}
 */
export const ZhuLiangFields = [{
  label: 'major element',
  options: [
    { value: 'SiO2', label: 'SiO2(wt%)' },
    { value: 'TiO2', label: 'TiO2(wt%)' },
    { value: 'Al2O3', label: 'Al2O3(wt%)' },
    { value: 'Fe2O3', label: 'Fe2O3(wt%)' },
    { value: 'Fe2O3T', label: 'Fe2O3T(wt%)' },
    { value: 'FeO', label: 'FeO(wt%)' },
    { value: 'FeOT', label: 'FeOT(wt%)' },
    { value: 'MnO', label: 'MnO(wt%)' },
    { value: 'MgO', label: 'MgO(wt%)' },
    { value: 'CaO', label: 'CaO(wt%)' },
    { value: 'Na2O', label: 'Na2O(wt%)' },
    { value: 'K2O', label: 'K2O(wt%)' },
    { value: 'P2O5', label: 'P2O5(wt%)' },
    { value: 'H2O', label: 'H2O(wt%)' },
    { value: 'H2O_bound_water', label: 'H2O+(wt%)' },
    { value: 'H2O_moisture_water', label: 'H2O-(wt%)' },
    { value: 'CO2', label: 'CO2(wt%)' },
    { value: 'CO3', label: 'SO3(wt%)' },
    { value: 'SO2', label: 'SO2(wt%)' },
    { value: 'LOI_loss_on_ignition', label: 'LOI(wt%)' },
    { value: 'F', label: 'F(wt%)' },
    { value: 'Cl', label: 'Cl(wt%)' },
    { value: 'Total', label: 'Total(wt%)' },
    { value: 'Cr2O3', label: 'Cr2O3(wt%)' },
    { value: 'NiO', label: 'NiO(wt%)' },
    { value: 'La2O3', label: 'La2O3(wt%)' },
    { value: 'Ce2O3', label: 'Ce2O3(wt%)' },
    { value: 'Pr2O3', label: 'Pr2O3(wt%)' },
    { value: 'Nd2O3', label: 'Nd2O3(wt%)' },
    { value: 'Sm2O3', label: 'Sm2O3(wt%)' },
    { value: 'Eu2O3', label: 'Eu2O3(wt%)' },
    { value: 'Gd2O3', label: 'Gd2O3(wt%)' },
    { value: 'Tb2O3', label: 'Tb2O3(wt%)' },
    { value: 'Dy2O3', label: 'Dy2O3(wt%)' },
    { value: 'Ho2O3', label: 'Ho2O3(wt%)' },
    { value: 'Er2O3', label: 'Er2O3(wt%)' },
    { value: 'Tm2O3', label: 'Tm2O3(wt%)' },
    { value: 'Yb2O3', label: 'Yb2O3(wt%)' },
    { value: 'Lu2O3', label: 'Lu2O3(wt%)' },
    { value: 'Y2O3', label: 'Y2O3(wt%)' },
    { value: 'UO2', label: 'UO2(wt%)' },
    { value: 'ThO2', label: 'ThO2(wt%)' },
    { value: 'PbO', label: 'PbO(wt%)' },
    { value: 'Li2O', label: 'Li2O(wt%)' },
    { value: 'Rb2O', label: 'Rb2O(wt%)' },
    { value: 'Cs2O', label: 'Cs2O(wt%)' },
    { value: 'BeO', label: 'BeO(wt%)' },
    { value: 'SrO', label: 'SrO(wt%)' },
    { value: 'BaO', label: 'BaO(wt%)' },
    { value: 'B2O3', label: 'B2O3(wt%)' },
    { value: 'In2O3', label: 'In2O3(wt%)' },
    { value: 'SnO2', label: 'SnO2(wt%)' },
    { value: 'As2O3', label: 'As2O3(wt%)' },
    { value: 'Sb2O3', label: 'Sb2O3(wt%)' },
    { value: 'Bi2O3', label: 'Bi2O3(wt%)' },
    { value: 'Sc2O3', label: 'Sc2O3(wt%)' },
    { value: 'ZrO2', label: 'ZrO2(wt%)' },
    { value: 'HfO2', label: 'HfO2(wt%)' },
    { value: 'V2O3', label: 'V2O3(wt%)' },
    { value: 'V2O5', label: 'V2O5(wt%)' },
    { value: 'Nb2O5', label: 'Nb2O5(wt%)' },
    { value: 'Ta2O5', label: 'Ta2O5(wt%)' },
    { value: 'MoO3', label: 'MoO3(wt%)' },
    { value: 'WO3', label: 'WO3(wt%)' },
    { value: 'CuO', label: 'CuO(wt%)' },
    { value: 'Ag2O3', label: 'Ag2O3(wt%)' },
    { value: 'ZnO', label: 'ZnO(wt%)' },
    { value: 'CdO', label: 'CdO(wt%)' },
  ]
}]

/**
 * 微量元素筛选条件字段列表
 * @type {[{options: [{label: string, value: string},{label: string, value: string}], label: string},{options: [{label: string, value: string},{label: string, value: string},{label: string, value: string}], label: string}]}
 */
export const WeiLiangFields = [{
  label: 'trace element',
  options: [
    { value: 'Li', label: 'Li(ppm)' },
    { value: 'Be', label: 'Be(ppm)' },
    { value: 'B', label: 'B(ppm)' },
    { value: 'K', label: 'K(ppm)' },
    { value: 'Sc', label: 'Sc(ppm)' },
    { value: 'V', label: 'V(ppm)' },
    { value: 'Cr', label: 'Cr(ppm)' },
    { value: 'Co', label: 'Co(ppm)' },
    { value: 'Ni', label: 'Ni(ppm)' },
    { value: 'Cu', label: 'Cu(ppm)' },
    { value: 'Zn', label: 'Zn(ppm)' },
    { value: 'Ga', label: 'Ga(ppm)' },
    { value: 'Ge', label: 'Ge(ppm)' },
    { value: 'Rb', label: 'Rb(ppm)' },
    { value: 'Sr', label: 'Sr(ppm)' },
    { value: 'Y', label: 'Y(ppm)' },
    { value: 'Zr', label: 'Zr(ppm)' },
    { value: 'Nb', label: 'Nb(ppm)' },
    { value: 'Cs', label: 'Cs(ppm)' },
    { value: 'Ba', label: 'Ba(ppm)' },
    { value: 'La', label: 'La(ppm)' },
    { value: 'Ce', label: 'Ce(ppm)' },
    { value: 'Pr', label: 'Pr(ppm)' },
    { value: 'Nd', label: 'Nd(ppm)' },
    { value: 'Sm', label: 'Sm(ppm)' },
    { value: 'Eu', label: 'Eu(ppm)' },
    { value: 'Gd', label: 'Gd(ppm)' },
    { value: 'Tb', label: 'Tb(ppm)' },
    { value: 'Dy', label: 'Dy(ppm)' },
    { value: 'Ho', label: 'Ho(ppm)' },
    { value: 'Er', label: 'Er(ppm)' },
    { value: 'Tm', label: 'Tm(ppm)' },
    { value: 'Yb', label: 'Yb(ppm)' },
    { value: 'Lu', label: 'Lu(ppm)' },
    { value: 'Hf', label: 'Hf(ppm)' },
    { value: 'Ta', label: 'Ta(ppm)' },
    { value: 'Tl', label: 'Tl(ppm)' },
    { value: 'Pb', label: 'Pb(ppm)' },
    { value: 'Bi', label: 'Bi(ppm)' },
    { value: 'Th', label: 'Th(ppm)' },
    { value: 'U', label: 'U(ppm)' },
    { value: 'Os', label: 'Os(ppm)' },
    { value: 'Ir', label: 'Ir(ppm)' },
    { value: 'Ru', label: 'Ru(ppm)' },
    { value: 'Rh', label: 'Rh(ppm)' },
    { value: 'Pt', label: 'Pt(ppm)' },
    { value: 'Pd', label: 'Pd(ppm)' },
    { value: 'Re', label: 'Re(ppm)' },
    { value: 'Ag', label: 'Ag(ppm)' },
    { value: 'Al', label: 'Al(ppm)' },
    { value: 'As_', label: 'As(ppm)' },
    { value: 'Au', label: 'Au(ppm)' },
    { value: 'Ca', label: 'Ca(ppm)' },
    { value: 'Cd', label: 'Cd(ppm)' },
    { value: 'Fe', label: 'Fe(ppm)' },
    { value: 'Hg', label: 'Hg(ppm)' },
    { value: 'In_', label: 'In(ppm)' },
    { value: 'Mg', label: 'Mg(ppm)' },
    { value: 'Mn', label: 'Mn(ppm)' },
    { value: 'Mo', label: 'Mo(ppm)' },
    { value: 'Na', label: 'Na(ppm)' },
    { value: 'P', label: 'P(ppm)' },
    { value: 'S', label: 'S(ppm)' },
    { value: 'Si', label: 'Si(ppm)' },
    { value: 'Sb', label: 'Sb(ppm)' },
    { value: 'Se', label: 'Se(ppm)' },
    { value: 'Sn', label: 'Sn(ppm)' },
    { value: 'Tc', label: 'Tc(ppm)' },
    { value: 'Te', label: 'Te(ppm)' },
    { value: 'Ti', label: 'Ti(ppm)' },
    { value: 'W', label: 'W(ppm)' },
  ]
}
]

/**
 * 定年
 * @type {[{options: [{label: string, value: string},{label: string, value: string}], label: string},{options: [{label: string, value: string},{label: string, value: string},{label: string, value: string}], label: string}]}
 */
export const DingNianFields = [
  {
    label: 'SIMS/LA-ICPMS dating',
    options: [
      { value: 'U_Th_Pb_U', label: 'U(ppm)' },
      { value: 'U_Th_Pb_Th', label: 'Th(ppm)' },
      { value: 'U_Th_Pb_Pb', label: 'Pb(ppm)' },
      { value: 'U_Th_Pb_Th_U', label: 'Th/U' },
      { value: 'D_207Pb_206Pb_age', label: '207Pb/206Pb age(Ma)' },
      { value: '207Pb/235U age', label: '207Pb/235U age(Ma)' },
      { value: 'D_206Pb_238U_age', label: '206Pb/238U age(Ma)' },
      { value: 'Disconcordance', label: 'Disconcordance(%)' },
      { value: 'D_208Pb_232Th_age', label: '208Pb/232Th age(Ma)' },
    ],
  },
  {
    label: 'TIMS dating',
    options: [
      { value: 'Dates_207Pb_235U', label: '207Pb/235U age (Ma)' },
      { value: 'Dates_206Pb_238U', label: '206Pb/238U age (Ma)' },
      { value: 'Dates_207Pb_206Pb', label: '207Pb/206Pb age (Ma)' },
    ],
  },
  {
    label: 'Ar or Ar-Ar dating',
    options: [
      { value: 'K_Ar_age', label: 'K-Ar age' },
      { value: 'Ar_Ar_age', label: 'Ar-Ar age' },
    ],
  },
  {
    label: 'Th/He dating',
    options: [
      { value: 'FT_corrected_age', label: 'FT corrected age' },
    ],
  }
]

/**
 * 同位素
 * @type {[{options: [{label: string, value: string},{label: string, value: string}], label: string},{options: [{label: string, value: string},{label: string, value: string},{label: string, value: string}], label: string}]}
 */
export const TongweiSuFields = [
  {
    label: 'Rb-Sr isotopes',
    options: [
      { value: 'Rb_Rb', label: 'Rb' },
      { value: 'Rb_Sr', label: 'Sr' },
      { value: 'Rb_87Rb_86Sr', label: '87Rb/86Sr' },
      { value: 'Rb_87Sr_86Sr', label: '87Sr/86Sr' },
    ],
  },
  {
    label: 'Sm-Nd isotopes',
    options: [
      { value: 'Sm_Sm', label: 'Sm' },
      { value: 'Sm_Nd', label: 'Nd' },
      { value: 'Sm_147Sm_144Nd', label: '147Sm/144Nd' },
      { value: 'Sm_143Nd_144Nd', label: '143Nd/144Nd' },
    ],
  },
  {
    label: 'Pb isotopes',
    options: [
      { value: 'Pb_206Pb_204Pb', label: '206Pb/204Pb' },
      { value: 'Pb_207Pb_204Pb', label: '207Pb/204Pb' },
      { value: 'Pb_208Pb_204Pb', label: '208Pb/204Pb' },
    ],
  },
  {
    label: 'Lu-Hf isotopes',
    options: [
      { value: 'Lu_Lu', label: 'Lu' },
      { value: 'Lu_Hf', label: 'Hf' },
      { value: 'Lu_176Yb177Hf', label: '176Yb/177Hf (only used for LA-ICPMS)' },
      { value: 'Lu_176Lu_177Hf', label: '176Lu/177Hf' },
      { value: 'Lu_176Hf_177Hf', label: '176Hf 177Hf' },
    ],
  },
  {
    label: 'Re-Os isotopes',
    options: [
      { value: 'Re_Re', label: 'Re' },
      { value: 'Re_Os', label: 'Os' },
      { value: 'Re_187Re_188Os', label: '187Re/188Os' },
      { value: 'Re_187Os_188Os', label: '187Os/188Os' },
    ],
  },
  {
    label: 'O isotopes',
    options: [
      { value: 'O_18OVSMOW', label: 'δ18OVSMOW' },
      { value: 'O_17OVSMOW', label: 'δ17OVSMOW' },
      { value: 'O_17O_2', label: 'Δ17O' },
    ],
  },
  {
    label: 'Other stable isotopes',
    options: [
      { value: 'delta_D', label: 'δD' },
      { value: 'delta_7Li', label: 'δ7Li' },
      { value: 'delta_11B', label: 'δ11B' },
      { value: 'delta_13C', label: 'δ13C' },
      { value: 'delta_15N', label: 'δ15N' },
      { value: 'delta_25Mg', label: 'δ25Mg' },
      { value: 'delta_26Mg', label: 'δ26Mg' },
      { value: 'delta_29Si', label: 'δ29Si' },
      { value: 'delta_30Si', label: 'δ30Si' },
      { value: 'delta_33S', label: 'δ33S' },
      { value: 'delta_34S', label: 'δ34S' },
      { value: 'delta_36S', label: 'δ36S' },
    ],
  },
]
