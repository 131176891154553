/*
 * @Author: lg9527 13691080728@126.com
 * @Date: 2023-11-08 13:43:50
 * @LastEditors: lg9527 13691080728@126.com
 * @LastEditTime: 2024-02-04 09:31:10
 * @FilePath: \zky_webVue\src\views\visualized\SearchResultLayer.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
import L from 'leaflet'
import { getRangePoint, getSampleBasicInfo, searchByCondition, searchByConditionData } from "@/api/sampleAPI";
var SearchResultLayer = L.Layer.extend({
  initialize: function() {
    this.circles = [];
    this.searchCondition = {}
  },

  updateCondition: function(condition) {
    this.searchCondition = condition
    this._update()
  },
  addCircle: function(lat, lng, id,sample_Name) {
    const sizeReslut = 15;
    const colorClassName = "text-label-single text-label-single"
    var text = L.divIcon({ iconSize: [sizeReslut, sizeReslut], iconAnchor: [sizeReslut / 2, sizeReslut / 2], className: colorClassName, html: sample_Name });
    var numberMarker = L.marker([lat, lng], { icon: text });
    // console.log("ddd"+id)
    this.circles.push(numberMarker);
    // this._map.addLayer(circle);
    this._map.addLayer(numberMarker);
    const sampleName = id

    numberMarker.on('click', async (e) => {
      const res = await getSampleBasicInfo(sampleName)
      const basicInfo = res.data
      console.log(basicInfo)
      var popupContent = `
    <div class="my-popup">
      <ul>
       <li>样品名称:<span>${basicInfo.sample_Name}</span></li>
       <li>年龄值(Ma):<span>${basicInfo.age}</span></li>
        <li>岩性小类:<span>${basicInfo.lithology_subdivision}</span></li>
        <li>岩石或地层单元:<span>${basicInfo.rock_or_stratigraphic_unit}</span></li>
        <li>测定对象:<span>${basicInfo.object_or_mineral_determined}</span></li>
      </ul>
      <a href="/#/search/result-detail/sample?sampleId=${sampleName}" target="_blank">详细信息</a>
     
    </div>
  `;
      var customPopup = L.popup().setContent(popupContent);
      customPopup.setLatLng(e.latlng).openOn(this._map);
    });
  },

  clearCircles: function() {
    this.circles.forEach(function(circle) {
      this._map.removeLayer(circle);
    }, this);
    this.circles = [];
  },

  fetchData: function() {
    this.searchCondition.pageSize = 1000
    return searchByConditionData(this.searchCondition)
  },

  _update: function() {
    this.fetchData()
      .then(function(response) {
        const data = response.data.results;

        if (data) {
          this.clearCircles();
          const arr = data;
          arr.forEach(function(item, index) {
            this.addCircle(item.latitude, item.longitude, item.sample_ID, item.sample_Name);
          }, this);
        }
      }.bind(this))
      .catch(function(error) {
        console.error('Error fetching data:', error);
      });
  },

  onAdd: function(map) {
    this._map = map;
    this._update();
  },
  onRemove: function(map) {
    this.clearCircles();
  }
});

export default SearchResultLayer
