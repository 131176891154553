
import request from '@/utils/request'

/**
 * 获取范围内的样品点
 * @param xmin
 * @param xmax
 * @param ymin
 * @param ymax
 * @returns {*}
 */
export function getRangePoint(xmin, xmax, ymin, ymax, step) {
  return request({
    url: '/D_Sample/geomap/get_point_coords',
    method: 'get',
    params: {
      step: step,
      xmin,
      xmax,
      ymax,
      ymin
    }
  })
}

/**
 * 检索样品列表
 * @param condition
 * @param pageNo
 * @param pageSize
 * @returns {*}
 */
// export function searchByCondition(condition, pageNo, pageSize) {
//   const { dicpmsFilters, dMicronutrientFilters, dPrincipalElementFilters, dOtopesFilters, ACondition, BCondition } = condition
//   const { rangeGeoJson, detailed_locality, terrane, rock_or_stratigraphic_unit, geological_period, lithology, object_or_mineral_determined, ageStart, ageEnd } = ACondition
//   const { sampleName, reference, lithology_subdivision, metamorphic_grade, expression, interpretation } = BCondition
//   const postData = {
//     rangeGeoJson: JSON.stringify(rangeGeoJson),
//     detailed_locality,
//     terrane,
//     rock_or_stratigraphic_unit,
//     geological_period,
//     lithology,
//     object_or_mineral_determined,
//     ageStart,
//     ageEnd,
//     sampleName,
//     reference,
//     lithology_subdivision,
//     // geodynamic_settings,
//     metamorphic_grade,
//     expression,
//     interpretation,
//     dicpmsFilters: dicpmsFilters,
//     dMicronutrientFilters: dMicronutrientFilters,
//     dPrincipalElementFilters: dPrincipalElementFilters,
//     dOtopesFilters: dOtopesFilters,
//     pageNo: pageNo,
//     pageSize
//   }
//   return searchByConditionData(postData)
// }

export function searchByConditionData(postData) {
  // 将查询条件写入ls,供其他页面获取
  localStorage.setItem("QUERY_CONDITION", JSON.stringify(postData));
  return request({
    url: '/D_Sample/geomap/search',
    method: 'post',
    data: postData
  })
}

/**
 * 获取单个样品的基本信息
 * @param sampleId
 * @returns {*}
 */
export function getSampleBasicInfo(sampleId) {
  return request({
    url: '/D_Sample/geomap/get_point',
    method: 'get',
    params: {
      sampleId
    }
  })
}

export function saveConditionRequest(name, history) {
  return request({
    url: '/D_Sample/geomap/search-history/create',
    method: 'post',
    data: {
      name,
      history
    }
  })
}

export function deleteConditionRequest(id) {
  return request({
    url: `/D_Sample/geomap/search-history/${id}/delete`,
    method: 'post'
  })
}

export function getConditionListRequest(name, history) {
  return request({
    url: '/D_Sample/geomap/search-history/list',
    method: 'get'
  })
}

// 获取数据分析图
export function getDataAnalysisImg(sampleIds) {
  return request({
    url: `/ApiAdmin/getpy_imgList`,
    method: 'get',
    params: {
      Sample_ID_GROUPBY_list: sampleIds.join(',')
    }
  })
}

// 获取数据分析图-概率累计图
export function GetPY_img_glljt(sampleIds) {
  return request({
    url: `/ApiAdmin/GetPY_img_glljt`,
    method: 'get',
    params: {
      Sample_ID_GROUPBY_list: sampleIds.join(',')
    }
  })
}
// 获取数据分析图-直方图
export function GetPY_img_zft(sampleIds) {
  return request({
    url: `/ApiAdmin/GetPY_img_zft`,
    method: 'get',
    params: {
      Sample_ID_GROUPBY_list: sampleIds.join(',')
    }
  })
}
// 获取数据分析图-提琴图
export function GetPY_img_tqt(sampleIds) {
  return request({
    url: `/ApiAdmin/GetPY_img_tqt`,
    method: 'get',
    params: {
      Sample_ID_GROUPBY_list: sampleIds.join(',')
    }
  })
}
// 获取数据分析图-二维投点图
export function GetPY_img_ewtdt(sampleIds) {
  return request({
    url: `/ApiAdmin/GetPY_img_ewtdt`,
    method: 'get',
    params: {
      Sample_ID_GROUPBY_list: sampleIds.join(',')
    }
  })
}
